import React, { useEffect, useState } from "react";
import background from "../../../_assets/media/login_back.jpg";
import Logo from "../../../_assets/media/favicon.png";
import { useForm } from "react-hook-form";
import { Alert } from "reactstrap";

import logo from "../../../_assets/media/home/logo.png";
import header1 from "../../../_assets/media/home/header1.jpg";
import header2 from "../../../_assets/media/home/header2.jpg";
import header3 from "../../../_assets/media/home/header3.jpg";
import { postMethod } from "../../../_services/_postMethod";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import NumberFormat from "react-number-format";
import { API_BASE_URL } from "../../../_services/constant";
import { Skeleton } from "@material-ui/lab";
import backgroundtest from "../../../_assets/media/home/WaitingBackground.JPG";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //  backgroundColor: '#fff'
  },
}));
const ExhibitionWelcome = (props) => {
  const classes = useStyles();
  const [otp, setOtp] = useState(false);
  const [imgObj, setimgObj] = useState({ image1: "", image2: "", image3: "" });
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const [otpVal, setotpVal] = useState(false);
  const [mobileVal, setmobileVal] = useState(false);
  const [mobile, setmobile] = useState("");
  const [Waitinglink, setWaitinglink] = useState(true);
  const [branchLogo, setBranchLogo] = useState("");
  const [branchid, setBranchid] = useState("");
  const [year, setyear] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchFacebook, setBranchFacebook] = useState("");
  const [branchInstagram, setBranchInstagram] = useState("");
  const [branchTwitter, setBranchTwitter] = useState("");
  const [branchLinkedin, setBranchLinkedin] = useState("");
  const loginCustomer = (values) => {
    var mobile = values.mobile
      .trim()
      .replace("+91", "")
      .replace(/ /g, "")
      .replace(/_/g, "");
    values.mobile = mobile;
    values.encrypt_id = branchid;

    if (mobile.length == 10) {
      setLoad(true);

      if (otp) {
        postMethod(`auth/exhibition/token`, values).then((dataD) => {
          var data = dataD.data[0];
          if (data) {
            localStorage.setItem("jwl_login", true);
            localStorage.setItem("type", "exhibitionSession");
            localStorage.setItem("jwl_token", data.access_token);
            localStorage.setItem("jwl_username", data.full_name);
            localStorage.setItem("jwl_customer_vcall", data.vcall_trial);
            localStorage.setItem("jwl_logo", data.logo);
            localStorage.setItem("jwl_brand_name", data.brand_name);
            localStorage.setItem("jwl_banner_path", data.banner_path);
            localStorage.setItem("jwl_customer_id", data.customer_id);
            localStorage.setItem("jwl_customer_branch_id", data.branch_id);
            localStorage.setItem("jwl_hide_price", data.hide_price);
            localStorage.setItem("cust_mobile", mobile);

            window.location = `/exhibition-view/home`;
          } else {
            setotpVal(!otpVal);
            setValid(true);
          }
          setLoad(false);
        });
      } else {
        values.mobile = mobile
          .trim()
          .replace("+91", "")
          .replace(" ", "")
          .replace(" ", "")
          .replace(" ", "");
        postMethod(`auth/exhibition/login`, values).then((dataD) => {
          if((dataD.data).length>0){
          var data = dataD.data[0];
            if (data.valid === true) {
              setOtp(true);
              setValid(false);
            } else {
              setOtp(false);
              setValid(true);
            }
          }else{
            setLoad(false);
          }
          setLoad(false);
        });
      }
    } else {
      setmobileVal(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      console.log(Waitinglink);
      setWaitinglink(false);
    }, 1000);
    const query = new URLSearchParams(window.location.search);
    var token = query.get("t");
    setBranchid(token);
    localStorage.clear();
    setyear(new Date().getFullYear());
    postMethod("public/Exeimages", { branch_id: token }).then((data) => {
      console.log(data.data)
      if (data.data.length > 0) {
        setimgObj(data.data[0]);
      }
    });
   
    setLoad(true);
    postMethod("public/branch_details", { branch_id: token }).then((data) => {
      setBranchLogo(data.data[0].logo);
      setBranchName(data.data[0].company_name);
      setBranchFacebook(data.data[0].facebook);
      setBranchInstagram(data.data[0].instagram);
      setBranchTwitter(data.data[0].twitter);

      localStorage.setItem("jwl_facebook", data.data[0].facebook);
      localStorage.setItem("jwl_branch_mobile", data.data[0].branch_mobile);
      localStorage.setItem("jwl_instagram", data.data[0].instagram);
      localStorage.setItem("jwl_twitter", data.data[0].twitter);
      setLoad(false);
    });
  }, []);
  const mystyle = {
    width: "300px",
    paddingTop: "230px",
    marginLeft: "200px",
  };
  const container = {
    position: "relative",
    textalign: "center",
    color: "white",
    height: "100%",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "Cover",
    backgroundPosition: "center",
  };
  const bottomright = {
    position: "absolute",
    bottom: "8px",
    right: "16px",
  };
  return (
    <React.Fragment>
      {Waitinglink === true && (
        <>
          <div
            style={{ backgroundImage: `url(${backgroundtest})`, ...container }}
          >
            <img
              style={mystyle}
              className="rounded mx-auto d-block"
              src="./logo_test.jpg"
            />
            {/* <h1 style={{textAlign:"center",color:'red'}}>Exhibition</h1> */}
          </div>
        </>
      )}
      {Waitinglink === false && (
        <React.Fragment>
          <Backdrop className={classes.backdrop} open={load}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="customcontainer">
            <div className="row toppad3">
              <div className="col-sm-8 logo_session">
                {branchLogo ? (
                  <img
                    src={API_BASE_URL + "BranchLogo/" + branchLogo}
                    className="logo"
                  />
                ) : (
                  <Skeleton variant="rectangular" width={210} height={118} />
                )}
              </div>
              <div className="col-sm-4"></div>
            </div>
            <div className="clr"></div>
            <div className="row">
              <div className="container-fluid bg-white p-4 gnrl_wrap">
                <div className="row mtoppad0">
                  <div className="col-md-4">
                    <img
                      src={API_BASE_URL + "Images/" + imgObj?.image1}
                       style={{ width: "375px" }}
                  className="img-fluid mainIMage"
                    />
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-7">
                    <div className="mtoppad3">
                      <div className="tophead">
                        <h4 className="d-none d-lg-block d-print-block">
                          UNIQUE ACCESSORIES
                        </h4>
                        <h1 className="d-none d-lg-block d-print-block">
                          Your Jewelry Collection
                          <br />
                          Forever
                        </h1>
                        <h4>Crafting Exquisite Jewellery for you..</h4>
                        <form onSubmit={handleSubmit(loginCustomer)}>
                          <div className={"group subs " + (!otp ? "" : "hide")}>
                            <NumberFormat
                              getInputRef={register}
                              onValueChange={(e) => {
                                setmobileVal(false);
                              }}
                              name="mobile"
                              format="+91 ### ### ####"
                              allowEmptyFormatting
                              mask="_"
                            />
                            <span className="highlight"></span>
                            {mobileVal ? (
                              <p style={{ color: "red" }}>
                                Incorrect mobile no.
                              </p>
                            ) : null}
                            <span className="bar"></span>
                          </div>

                          <div className={"group subs " + (!otp ? "hide" : "")}>
                            <input
                              className="form-control form-control-solid h-auto py-3 px-6"
                              ref={register}
                              type="number"
                              placeholder="Enter 6-digit OTP"
                              name="otp"
                              autoComplete="off"
                              style={{ fontWeight: "600" }}
                              onClick={() => {
                                setotpVal(false);
                              }}
                            />
                            {otpVal ? (
                              <p style={{ color: "red" }}>Incorrect OTP.</p>
                            ) : null}
                          </div>
                          <div className="sbmt">
                            <input
                              type="submit"
                              value="Go"
                              className="sbmtbtn"
                            />
                          </div>
                        </form>
                      </div>
                      <div className="tophead d-none d-lg-block d-print-block">
                        <div className="row">
                          <div
                            className="col-md-4 d-flex align-items-center"
                            style={{ gap: "15px" }}
                          >
                            <img
                              src={API_BASE_URL + "Images/" + imgObj?.image2}
                              style={{ width: "315px" }}
                              className="img-fluid"
                            />
                            <img
                              src={API_BASE_URL + "Images/" + imgObj?.image3}
                              style={{ width: "315px" }}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {window.outerWidth > 600 ? (
            <footer className="tag_footer">
              <div className="container mcntr">
                <div className="row">
                  <div className="col-sm-10">
                    © {year} {branchName} All Rights Reserved
                  </div>
                  <div className="col-sm-2">
                    <div className="text-center center-block">
                      {branchFacebook ? (
                        <a href={branchFacebook}>
                          <i
                            id="social-fb"
                            className="fa fa-facebook fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                      {branchInstagram ? (
                        <a href={branchInstagram}>
                          <i
                            id="social-gp"
                            className="fa fa-instagram fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                      {branchLinkedin ? (
                        <a href={branchLinkedin}>
                          <i
                            id="social-em"
                            className="fa fa-linkedin fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                      {branchTwitter ? (
                        <a href={branchTwitter}>
                          <i
                            id="social-tw"
                            className="fa fa-twitter fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ExhibitionWelcome;
