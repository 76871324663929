import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./app/modules/auth/login";
import Welcome from "./app/modules/auth/loginType";
import "./App.css";
import Layout_Admin from "./app/layout/Admin";
import Layout_Branch from "./app/layout/Branch";
import Layout_Company from "./app/layout/Company";
import Layout_General from "./app/layout/General";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ProtectedRoute from "./app/modules/auth/protectedroutes";
import General from "./app/screens/public/general";
import Layout_Exhibition from "./app/layout/Exhibition";
import Layout_Trial from "./app/layout/Trial";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Feedback from "./app/screens/public/feedback";
import TabToScan from "./app/screens/branch/tab_to_scan/tabtoscan";
import Layout_Customized_Customer from "./app/layout/CustomizedCustomer";
import CustomerCustomizedWelcome from "./app/screens/public/customerCustomized";
import GeneralWelcome from "./app/screens/public/generalSession";
import Layout_General_Session from "./app/layout/GeneralSession";
import ExhibitionWelcome from "./app/screens/public/exhibition";
import BusinessWelcome from "./app/screens/public/business"; 
import Layout_Business from "./app/layout/Business";
import TrialWelcome from "./app/screens/public/trial";
import MaliramCheckCustomer from "./app/modules/auth/maliramCheckCustomer";
import CustomerDesign from "./app/screens/public/CustomerDesign";
import CustDesign from "./app/screens/customerCRM/design";
import RateUS from "./app/screens/public/RateUS";
import CustUpdate from "./app/screens/customerCRM/customerupdate";
import CustUpdateInfo from "./app/screens/customerCRM/customerinforamtionupdate";


function App() {
  const type = localStorage.getItem("type");
  const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#f16f21",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#ffcc00",
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            {/* for all Products */}
            <Route path="/g" component={General} />
            <Route path="/d" component={CustomerDesign} />
            <Route path="/c" component={CustomerCustomizedWelcome} />
            <Route path="/cust/design" component={CustDesign} />
            <Route path="/u" component={CustUpdate} /> 
            <Route path="/cust/infoupdate" component={CustUpdateInfo} />
            <Route path="/r" component={RateUS} />
            {/* General Session  */}
            <Route path="/p" component={GeneralWelcome} />
            <Route path="/e" component={ExhibitionWelcome} /> 
            <Route path="/t" component={TrialWelcome} />
            <Route path="/business" component={BusinessWelcome} />
            <Route path="/Welcome" component={Welcome} />
            <Route path="/Event" component={MaliramCheckCustomer} />

            <ProtectedRoute path="/tabtoscan" component={TabToScan} />
            {type === "admin" && (
              <ProtectedRoute path="/admin" component={Layout_Admin} />
            )}
            {type === "branch" && (
              <ProtectedRoute path="/branch" component={Layout_Branch} />
            )}
            {type === "company" && (
              <ProtectedRoute path="/company" component={Layout_Company} />
            )}
            {type === "exhibitionSession" && (
              <ProtectedRoute
                path="/exhibition-view"
                component={Layout_Exhibition}
              />
            )}
            {type === "trialSession" && (
              <ProtectedRoute path="/trial-view" component={Layout_Trial} />
            )}
            {type === "businessSession" && (
              <ProtectedRoute
                path="/business-view"
                component={Layout_Business}
              />
            )}
            {type === "CustomerSession" && (
              <ProtectedRoute
                path="/customersession"
                component={Layout_Customized_Customer}
              />
            )}
            {/* General Session Routes */}
            {type === "generalSession" && (
              <ProtectedRoute
                path="/generalsession"
                component={Layout_General_Session}
              />
            )}
            {/* Routing for General all products*/}
            {type === "general" && (
              <ProtectedRoute path="/general" component={Layout_General} />
            )}
            <Route path="/f" component={Feedback} />
            <Redirect to="/Welcome" from="/" />
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
