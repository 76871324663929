import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../../../_services/constant";
import { Form, FormGroup, Label, Row, Col, Alert } from "reactstrap";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { post } from "axios";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { DateRangePicker } from "react-date-range";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
const AddBranch = (props) => {
  let queryParams = useQuery();
  const classes = useStyles();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [imageValidation1, setImageValidation1] = useState(false);
  const [imageValidation2, setImageValidation2] = useState(false);

  const [serviceRem, setServiceRem] = useState({
    s_text1: "",
    s_text2: "",
    s_text3: "",
    s_text4: "",
  });

  const [branchData, setBranch] = useState({
    branch_id: "",
    branch_code: "",
    company_name: localStorage.getItem("jwl_username"),
    brand_name: "",
    address: "",
    city: "",
    mobile: "",
    pin: "",
    state: "",
    country: "",
    logo: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    banner_path: "",
    miss_call_user_id: "",
    sms_id: "",
    sms_password: "",
    sms_sender_id: "",
    sms_brand_name: "",
    estore_link: "",
    disable: false,
    hide_price: false,
    vcall_general: false,
    vcall_exhibition: false,
    vcall_trial: false,
    vcall_customer: false,
    branch_type: "",
    whatsapp_api_key: "",
    whatsapp_status: 0,
    whatsapp_number: "",
    send_whatsapp_to_client: "",
    send_to_owner: "",
    client_template: `Dear {customer_name}
your appointment for {category} is scheduled for today at {time}. hope to see you.
thanks {brand_name}`,
    owner_template: `Dear {brand_name},
you fixed an appointment with {customer_name} handled by {staff_name}  today at {time}.
thank you 
{brand_name}`,
    point_sys_status: false,
    point_per_rs: "",
    reference_point: "",
    per_visit_point: "",
    points_exsys_status: false,
    no_of_days: "",
    particular_date: "",
    remainder_bk_days1: "",
    remainder_bk_days2: "",
    rem_particular_day: "",
    point_temp_status: false,
    earn_point_temp:
      "Dear(customer name), thank you for shopping with us. You can (earn points) reward points in your account.Your total reward points are (total points). Team(brand name).",
    redemption_temp:
      "Dear(customer name), thank you for shopping with us.You redeemed (redeem points) reward points Remaining total reward points are (total points).Team (brand name).",
    point_rem_temp:
      "Dear(customer name), (brand name) like to inform yout that your (expiry points) reward points will expire on (expiry date).SHOP NOW and make it count.Your total reward points are (total points).Team (brand name).",
    whatsap_user_id: "",
    whatsap_user_pass: "",
    whtsapp_creadit: "",
    earn_v3: "",
    earn_v4: "",
    earn_v5: "",
    redeem_v3: "",
    redeem_v4: "",
    redeem_v5: "",
    remainder_v3: "",
    remainder_v4: "",
    remainder_v5: "",
    owner_v3: "",
    owner_v4: "",
    owner_v5: "",
    customer_v3: "",
    customer_v4: "",
    customer_v5: "",
    service_rem_status: false,
    service_remainder_day1: "",
    service_remainder_day2: "",
    rem_particular_service_day: "",
    servRem3: "",
    servRem4: "",
    servRem5: "",
  });
  const [imageObj, setImageObj] = useState({
    logo: "",
    banner: "",
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const addEntry = (values) => {
    // let file = document.getElementById("uploadImage");
    // let files = file.files;
    // let formData = new FormData();

    // let fileBanner = document.getElementById("branchbanner");
    // let filesBanner = fileBanner.files;
    // let formDataBanner = new FormData();

    // if (branchData.logo == "" && files.length == 0) {
    //   setImageValidation1(true);
    // } else if (branchData.banner_path == "" && filesBanner.length == 0) {
    //   setImageValidation2(true);
    // } else {
    //   setLoadInsert(true);

    //   if (files.length > 0) {
    //     for (var j = 0; j < files.length; j++) {
    //       let file_name = "image-" + createGuid();
    //       branchData.logo =
    //         file_name +
    //         "" +
    //         files[j].name.substring(files[j].name.lastIndexOf("."));
    //       formData.append("files", files[j], file_name);
    //     }
    //   }
    //   if (filesBanner.length > 0) {
    //     for (var j = 0; j < filesBanner.length; j++) {
    //       let file_name = "Banner-" + createGuid();
    //       branchData.banner_path =
    //         file_name +
    //         "" +
    //         filesBanner[j].name.substring(filesBanner[j].name.lastIndexOf("."));
    //       formDataBanner.append("files", filesBanner[j], file_name);
    //     }
    //   }

    let file = document.getElementById("uploadImage");
    let files = file.files;
    let formData = new FormData();

    let fileBanner = document.getElementById("branchbanner");
    let filesBanner = fileBanner.files;
    let formDataBanner = new FormData();

    if (branchData.logo == "" && files.length == 0) {
      setImageValidation1(true);
    } else if (branchData.banner_path == "" && filesBanner.length == 0) {
      setImageValidation2(true);
    } else {
      setLoadInsert(true);

      if (files.length > 0) {
        for (var j = 0; j < files.length; j++) {
          let file_name = "image-" + createGuid();
          let fileExtension = files[j].name.substring(
            files[j].name.lastIndexOf(".")
          );

          // Check for .jpeg extension and convert it to .jpg
          if (fileExtension.toLowerCase() === ".jpeg") {
            fileExtension = ".jpg";
          }

          branchData.logo = file_name + fileExtension;
          formData.append("files", files[j], file_name + fileExtension);
        }
      }

      if (filesBanner.length > 0) {
        for (var j = 0; j < filesBanner.length; j++) {
          let file_name = "Banner-" + createGuid();
          let fileExtension = filesBanner[j].name.substring(
            filesBanner[j].name.lastIndexOf(".")
          );

          // Check for .jpeg extension and convert it to .jpg
          if (fileExtension.toLowerCase() === ".jpeg") {
            fileExtension = ".jpg";
          }

          branchData.banner_path = file_name + fileExtension;
          formDataBanner.append(
            "files",
            filesBanner[j],
            file_name + fileExtension
          );
        }
      }

      postMethod("api/masters/branch/insert", branchData).then((data) => {

        if (data.valid) {
          if (files.length > 0) {
            uploadImageFunc();
          } else if (filesBanner.length > 0) {
            uploadImageBannerFunc();
          } else {
            setSuccess(true);
            setTimeout(() => {
              props.history.push("/company/branch");
            }, 2000);
          }
        }
      });
    }
  };
  const uploadImageFunc = () => {
    let file = document.getElementById("uploadImage");
    let files = file.files;
    let formData = new FormData();

    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {
        formData.append("files", files[j], branchData.logo.split(".")[0]);
      }
    }

    const url = API_BASE_URL + `api/masters/branch/UploadBranchLogo`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        let fileBanner = document.getElementById("branchbanner");
        let filesBanner = fileBanner.files;
        if (filesBanner.length > 0) {
          uploadImageBannerFunc();
        } else {
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/company/branch");
          }, 2000);
        }
      },
      (error) => {
        setSuccess(true);
        setLoadInsert(false);
      }
    );
  };

  const uploadImageBannerFunc = () => {
    let fileBanner = document.getElementById("branchbanner");
    let filesBanner = fileBanner.files;
    let formDataBanner = new FormData();

    if (filesBanner.length > 0) {
      for (var j = 0; j < filesBanner.length; j++) {
        formDataBanner.append(
          "files",
          filesBanner[j],
          branchData.banner_path.split(".")[0]
        );
      }
    }

    const url = API_BASE_URL + `api/masters/branch/UploadBranchBanner`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formDataBanner, config).then(
      (response) => {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/company/branch");
        }, 2000);
      },
      (error) => {
        setSuccess(true);
        setLoadInsert(false);
      }
    );
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");
    setLoadDelete(true);
    if (proceed) {
      postMethod("api/masters/branch/delete", {
        branch_id: branchData.branch_id,
      }).then((data) => {
        if (data.valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/company/branch");
          }, 2000);
        } else {
          setSuccessDelete(true);
          setTimeout(() => {
            setSuccessDelete(false);
          }, 4000);
          setLoadDeleteMsg(data.error);
          setLoadDelete(false);
        }
      });
    }
  };

  // useEffect(() => {
  //   if (id !== null) {
  //     setLoad(true);
  //     var temp = window.atob(id);
  //     postMethod("api/masters/branch/preview", { branch_id: temp }).then(
  //       (data) => {
  //         if (data) {
  //           // Split the string based on the placeholders
  //           const parts = data.servRem3.split(/\$\$(.*?)\$\$/);

  //           // Extract the different parts
  //           const s_text1 = parts[0].trim(); // Everything before $$category$$
  //           const s_text2 = parts[1] || ""; // Everything between $$category$$ and $$vichle number$$
  //           const s_text3 = parts[3] || ""; // Everything between $$vichle number$$ and $$date$$
  //           const s_text4 = parts.slice(4).join(" $$"); // Remaining parts after $$date$$

  //           setServiceRem({
  //             s_text1,
  //             s_text2,
  //             s_text3,
  //             s_text4,
  //           });
  //         }

  //         setBranch(data);
  //         setImageObj({
  //           ...imageObj,
  //           logo: data.logo,
  //           banner: data.banner_path,
  //         });
  //         setLoad(false);
  //       }
  //     );
  //   } else {
  //     postMethod("api/masters/branch/preview", { branch_id: "0" }).then(
  //       (data) => {
  //         setBranch({ ...branchData, branch_code: data.branch_code });
  //         setLoad(false);
  //       }
  //     );
  //   }
  // }, []);
  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/branch/preview", { branch_id: temp }).then(
        (data) => {
          setBranch(data);
          if((data.servRem3 != null)&&(data.servRem3 !== "")) {
             // Extracting and splitting servRem3 into individual parts
          const [s_text1, rest1] = data.servRem3.split("$$Category$$ $$Sub Category$$");
          const [s_text2, rest2] = rest1.split("$$Vehicle Number$$");
          const [s_text3, s_text4] = rest2.split("$$Due Date$$");
          
          setServiceRem({
            s_text1,
            s_text2,
            s_text3,
            s_text4,
          });
          }
          
          setImageObj({
            ...imageObj,
            logo: data.logo,
            banner: data.banner_path,
          });
          
          setLoad(false);
        }
      );
    } else {
      postMethod("api/masters/branch/preview", { branch_id: "0" }).then(
        (data) => {
          setBranch({ ...branchData, branch_code: data.branch_code });
          setLoad(false);
        }
      );
    }
  }, []);

  // ------------
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setBranch({ ...branchData, [name]: value });
  // };

  const handleDaysChange = (e) => {
    const value = e.target.value;
    setBranch({
      ...branchData,
      no_of_days: value,
      particular_date: value ? "" : branchData.particular_date,
    });
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    const today = new Date();
    const selectedDate = new Date(value);

    // Check if the selected date is smaller than today's date
    if (selectedDate < today) {
      alert("Selected date cannot be earlier than today's date");
      setBranch({
        ...branchData,
        particular_date: "",
        no_of_days: value ? "" : branchData.no_of_days,
      });
      return; // Stop further execution
    }

    setBranch({
      ...branchData,
      particular_date: value,
      no_of_days: value ? "" : branchData.no_of_days,
    });
  };

  // ----------
  const getServiceDateFormat = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    return formattedDate;
  };

  // ---------
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(addEntry)}>
        <input
          type="hidden"
          name="branch_id"
          value={id == null ? "0" : branchData.branch_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Branch</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col>
                <FormGroup>
                  <Label>Branch Code</Label>
                  <input
                    type="text"
                    required
                    value={branchData.branch_code}
                    className="form-control h-auto py-2 px-6"
                    name="branch_code"
                    readonly
                    onChange={(e) =>
                      setBranch({ ...branchData, branch_code: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Company Name</Label>
                  <input
                    type="text"
                    required
                    value={branchData.company_name}
                    className="form-control h-auto py-2 px-6"
                    name="company_name"
                    onChange={(e) =>
                      setBranch({ ...branchData, company_name: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Brand Name</Label>
                  <input
                    type="text"
                    required
                    value={branchData.brand_name}
                    className="form-control h-auto py-2 px-6"
                    name="brand_name"
                    onChange={(e) =>
                      setBranch({ ...branchData, brand_name: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Address</Label>
                  <input
                    type="text"
                    required
                    value={branchData.address}
                    className="form-control h-auto py-2 px-6"
                    name="address"
                    onChange={(e) =>
                      setBranch({ ...branchData, address: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>City</Label>
                  <input
                    type="text"
                    required
                    value={branchData.city}
                    className="form-control h-auto py-2 px-6"
                    name="city"
                    onChange={(e) =>
                      setBranch({ ...branchData, city: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Pin</Label>
                  <input
                    type="text"
                    required
                    value={branchData.pin}
                    className="form-control h-auto py-2 px-6"
                    name="pin"
                    onChange={(e) =>
                      setBranch({ ...branchData, pin: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>State</Label>
                  <input
                    type="text"
                    required
                    value={branchData.state}
                    className="form-control h-auto py-2 px-6"
                    name="state"
                    onChange={(e) =>
                      setBranch({ ...branchData, state: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Country</Label>
                  <input
                    type="text"
                    required
                    value={branchData.country}
                    className="form-control h-auto py-2 px-6"
                    name="country"
                    onChange={(e) =>
                      setBranch({ ...branchData, country: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4} className="d-flex">
                <FormGroup>
                  <Label>
                    Logo{" "}
                    {imageValidation1 ? (
                      <label className="text-danger">*Required</label>
                    ) : null}
                    {imageObj.logo != "" && imageObj.logo != undefined ? (
                      <img
                        id="branchlogo"
                        width="100"
                        alt="logo"
                        src={API_BASE_URL + "BranchLogo/" + imageObj.logo}
                      ></img>
                    ) : null}
                    <input
                      id="uploadImage"
                      type="file"
                      multiple={true}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e) => {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                          setImageObj({ ...imageObj, logo: e.target.result });
                          document.getElementById("branchlogo").src =
                            "" + e.target.result + "";
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        setImageValidation1(false);
                      }}
                    />
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>facebook link</Label>
                  <input
                    type="text"
                    required
                    value={branchData.facebook}
                    className="form-control h-auto py-2 px-6"
                    name="facebook"
                    onChange={(e) =>
                      setBranch({ ...branchData, facebook: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Instagram link</Label>
                  <input
                    type="text"
                    required
                    value={branchData.instagram}
                    className="form-control h-auto py-2 px-6"
                    name="instagram"
                    onChange={(e) =>
                      setBranch({ ...branchData, instagram: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Twitter link</Label>
                  <input
                    type="text"
                    required
                    value={branchData.twitter}
                    className="form-control h-auto py-2 px-6"
                    name="twitter"
                    onChange={(e) =>
                      setBranch({ ...branchData, twitter: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Youtube link</Label>
                  <input
                    type="text"
                    required
                    value={branchData.youtube}
                    className="form-control h-auto py-2 px-6"
                    name="youtube"
                    onChange={(e) =>
                      setBranch({ ...branchData, youtube: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Contact Number</Label>
                  <input
                    type="text"
                    required
                    className="form-control h-auto py-2 px-6"
                    name="mobile"
                    value={branchData.mobile}
                    onChange={(e) =>
                      setBranch({ ...branchData, mobile: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Branch Type</Label>
                  <select
                    required
                    className="form-control h-auto py-2 px-6"
                    name="branch_type"
                    value={branchData.branch_type}
                    onChange={(e) =>
                      setBranch({ ...branchData, branch_type: e.target.value })
                    }
                    ref={register}
                  >
                    <option value="">Select Branch Type</option>
                    <option value="2-Vehicle">2-Vehicle</option>
                    <option value="Jeweller">Jeweller</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <hr />
            <h5>Notification Details</h5>
            <br></br>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Miss call user ID</Label>
                  <input
                    type="text"
                    required
                    value={branchData.miss_call_user_id}
                    className="form-control h-auto py-2 px-6"
                    name="miss_call_user_id"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        miss_call_user_id: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>SMS ID</Label>
                  <input
                    type="text"
                    required
                    value={branchData.sms_id}
                    className="form-control h-auto py-2 px-6"
                    name="sms_id"
                    onChange={(e) =>
                      setBranch({ ...branchData, sms_id: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>SMS Password</Label>
                  <input
                    type="text"
                    required
                    value={branchData.sms_password}
                    className="form-control h-auto py-2 px-6"
                    name="sms_password"
                    onChange={(e) =>
                      setBranch({ ...branchData, sms_password: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>SMS Sender ID</Label>
                  <input
                    type="text"
                    required
                    value={branchData.sms_sender_id}
                    className="form-control h-auto py-2 px-6"
                    name="sms_sender_id"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        sms_sender_id: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Brand Name For SMS</Label>
                  <input
                    type="text"
                    required
                    value={branchData.sms_brand_name}
                    className="form-control h-auto py-2 px-6"
                    name="sms_brand_name"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        sms_brand_name: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <h5>Whatsapp Details</h5>
            <br></br>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>WhatsApp API Key</Label>
                  <input
                    type="text"
                    value={branchData.whatsapp_api_key}
                    className="form-control h-auto py-2 px-6"
                    name="whatsapp_api_key"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        whatsapp_api_key: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>WhatsApp Id</Label>
                  <input
                    type="text"
                    value={branchData.whatsap_user_id}
                    className="form-control h-auto py-2 px-6"
                    name="whatsap_user_id"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        whatsap_user_id: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>WhatsApp Password</Label>
                  <input
                    type="text"
                    value={branchData.whatsap_user_pass}
                    className="form-control h-auto py-2 px-6"
                    name="whatsap_user_pass"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        whatsap_user_pass: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Creadit Whatsapp Message</Label>
                  <input
                    type="number"
                    value={branchData.whtsapp_creadit}
                    className="form-control h-auto py-2 px-6"
                    name="whtsapp_creadit"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        whtsapp_creadit: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <h5>Settings</h5>
            <br></br>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Whatsapp Number (Without +91)</Label>
                  <input
                    type="text"
                    value={branchData.whatsapp_number}
                    className="form-control h-auto py-2 px-6"
                    name="whatsapp_number"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        whatsapp_number: e.target.value,
                      })
                    }
                    // ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Estore Link</Label>
                  <input
                    type="text"
                    required
                    value={branchData.estore_link}
                    className="form-control h-auto py-2 px-6"
                    name="estore_link"
                    onChange={(e) =>
                      setBranch({ ...branchData, estore_link: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4} className="d-flex">
                <FormGroup>
                  <Label>
                    Custommer Session Banner(width 1110 X Height 257){" "}
                    {imageValidation2 ? (
                      <label className="text-danger">*Required</label>
                    ) : null}
                    {imageObj.banner != "" && imageObj.banner != undefined ? (
                      <img
                        id="branchbannershow"
                        width="100"
                        alt="banner"
                        src={API_BASE_URL + "BranchBanner/" + imageObj.banner}
                      ></img>
                    ) : null}
                    <input
                      id="branchbanner"
                      type="file"
                      multiple={true}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e) => {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                          setImageObj({ ...imageObj, banner: e.target.result });
                          document.getElementById("branchbannershow").src =
                            "" + e.target.result + "";
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        setImageValidation2(false);
                      }}
                    />
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <FormGroup>
                  <Label>General Video Call</Label>
                  <input
                    type="checkbox"
                    checked={branchData.vcall_general}
                    className="form-control h-auto py-2 px-6"
                    name="vcall_general"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        vcall_general: !branchData.vcall_general,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={2}>
                <FormGroup>
                  <Label>Exhibition Video Call</Label>
                  <input
                    type="checkbox"
                    checked={branchData.vcall_exhibition}
                    className="form-control h-auto py-2 px-6"
                    name="vcall_exhibition"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        vcall_exhibition: !branchData.vcall_exhibition,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={2}>
                <FormGroup>
                  <Label>Trial Video Call</Label>
                  <input
                    type="checkbox"
                    checked={branchData.vcall_trial}
                    className="form-control h-auto py-2 px-6"
                    name="vcall_trial"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        vcall_trial: !branchData.vcall_trial,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={2}>
                <FormGroup>
                  <Label>Customer Video Call</Label>
                  <input
                    type="checkbox"
                    checked={branchData.vcall_customer}
                    className="form-control h-auto py-2 px-6"
                    name="disable"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        vcall_customer: !branchData.vcall_customer,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Enable Whatsapp Messages</Label>
                  <input
                    type="checkbox"
                    checked={branchData.whatsapp_status}
                    className="form-control h-auto py-2 px-6"
                    name="whatsapp_status"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        whatsapp_status: !branchData.whatsapp_status,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <FormGroup>
                  <Label>send_whatsapp_to_client</Label>
                  <input
                    type="checkbox"
                    checked={branchData.send_whatsapp_to_client}
                    className="form-control h-auto py-2 px-6"
                    name="send_whatsapp_to_client"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        send_whatsapp_to_client:
                          !branchData.send_whatsapp_to_client,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={2}>
                <FormGroup>
                  <Label>send_to_owner</Label>
                  <input
                    type="checkbox"
                    checked={branchData.send_to_owner}
                    className="form-control h-auto py-2 px-6"
                    name="send_to_owner"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        send_to_owner: !branchData.send_to_owner,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={1}>
                <FormGroup>
                  <Label>Disable</Label>
                  <input
                    type="checkbox"
                    checked={branchData.disable}
                    className="form-control h-auto py-2 px-6"
                    name="disable"
                    onChange={(e) =>
                      setBranch({ ...branchData, disable: !branchData.disable })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={2}>
                <FormGroup>
                  <Label>Hide Price</Label>
                  <input
                    type="checkbox"
                    checked={branchData.hide_price}
                    className="form-control h-auto py-2 px-6"
                    name="hide_price"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        hide_price: !branchData.hide_price,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />

            {/*  */}

            <Typography variant="h6" className="mt-4">
              <span>Active :</span>{" "}
              <input
                type="checkbox"
                checked={branchData.point_sys_status}
                name="point_sys_status"
                onChange={(e) =>
                  setBranch({
                    ...branchData,
                    point_sys_status: !branchData.point_sys_status,
                  })
                }
                ref={register}
              />
            </Typography>

            <h5>Points System </h5>
            <br></br>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Points/1Rs.</Label>
                  <input
                    type="text"
                    className="form-control h-auto py-2 px-6"
                    value={branchData.point_per_rs}
                    name="point_per_rs"
                    onChange={(e) =>
                      setBranch({ ...branchData, point_per_rs: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Reference Points</Label>
                  <input
                    type="text"
                    className="form-control h-auto py-2 px-6"
                    value={branchData.reference_point}
                    name="reference_point"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        reference_point: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Per Visit Points</Label>
                  <input
                    type="text"
                    className="form-control h-auto py-2 px-6"
                    value={branchData.per_visit_point}
                    name="per_visit_point"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        per_visit_point: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <Typography variant="h6" className="mt-4">
              <span>Active :</span>{" "}
              <input
                type="checkbox"
                checked={branchData.points_exsys_status}
                name="points_exsys_status"
                onChange={(e) =>
                  setBranch({
                    ...branchData,
                    points_exsys_status: !branchData.points_exsys_status,
                  })
                }
                ref={register}
              />
            </Typography>

            <h5>Points Expired System </h5>
            <br></br>

            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>No. of Days</Label>
                  <input
                    type="number"
                    className="form-control h-auto py-2 px-6"
                    value={branchData.no_of_days}
                    name="no_of_days"
                    onChange={handleDaysChange}
                    ref={register}
                    disabled={!!branchData.particular_date}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label>Particular Date</Label>
                  <input
                    type="date"
                    className="form-control h-auto py-2 px-6"
                    value={getServiceDateFormat(branchData.particular_date)}
                    name="particular_date"
                    onChange={handleDateChange}
                    ref={register}
                    disabled={!!branchData.no_of_days}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Reminder No.Of Days Back1</Label>
                  <input
                    type="text"
                    className="form-control h-auto py-2 px-6"
                    value={branchData.remainder_bk_days1}
                    name="days_bk_remainder"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        remainder_bk_days1: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Reminder No.Of Days Back2</Label>
                  <input
                    type="text"
                    className="form-control h-auto py-2 px-6"
                    value={branchData.remainder_bk_days2}
                    name="remainder_bk_days2"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        remainder_bk_days2: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Particular Day</Label>
                  <input
                    type="checkbox"
                    checked={branchData.rem_particular_day}
                    className="form-control h-auto py-2 px-6"
                    name="rem_particular_day"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        rem_particular_day: !branchData.rem_particular_day,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <Typography variant="h6" className="mt-4">
              <span>Active :</span>{" "}
              <input
                type="checkbox"
                checked={branchData.point_temp_status}
                name="point_temp_status"
                onChange={(e) =>
                  setBranch({
                    ...branchData,
                    point_temp_status: !branchData.point_temp_status,
                  })
                }
                ref={register}
              />
            </Typography>

            <h5>Points Template </h5>
            <br></br>

            {/* -----Earn Templates-----*/}

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Earn Templates
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear CUSTOMER NAME, thank you for shopping with us. You earn
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.earn_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        earn_v3: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  reward points in your account. Your total reward points are
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.earn_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        earn_v4: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.earn_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        earn_v5: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  <h5 className="d-flex" style={{ fontSize: "11px" }}>
                    Dear CUSTOMER NAME, thank you for shopping with us. You earn
                    $$EARN POINTS$$ reward points in your account. Your total
                    reward points are $$TOTAL POINTS$$. Team BRAND NAME
                  </h5>
                </Typography>

                <br></br>
              </CardContent>
            </Card>
            {/* ----- */}

            {/* -----Points Redemption Template-----*/}

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Points Redemption Template
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear CUSTOMER NAME, thank you for shopping with us. You
                  redeemed
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.redeem_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        redeem_v3: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  reward points. Remaining total reward points are
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.redeem_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        redeem_v4: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.redeem_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        redeem_v5: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  <h5 className="d-flex" style={{ fontSize: "11px" }}>
                    Dear CUSTOMER NAME, thank you for shopping with us. You
                    redeemed $$redeem points$$ reward points. Remaining total
                    reward points are $$Total points$$. Team BRAND NAME.
                  </h5>
                </Typography>

                <br></br>
              </CardContent>
            </Card>
            {/* ----- */}

            {/* -----Points Reminder Template-----*/}

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Points Reminder Template
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear CUSTOMER NAME, BRAND NAME like to inform you that your
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.remainder_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        remainder_v3: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  reward points will expire on
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.remainder_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        remainder_v4: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  SHOP NOW and make it count. Your total reward points are
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.remainder_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        remainder_v5: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  Team BRAND NAME.
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  <h5 className="d-flex" style={{ fontSize: "11px" }}>
                    Dear CUSTOMER NAME, BRAND NAME like to inform you that your
                    $$EXPIRING POINTS$$ reward points will expire on $$DATE$$.
                    SHOP NOW and make it count. Your total reward points are
                    $$TOTAL POINTS$$. Team BRAND NAME.
                  </h5>
                </Typography>

                <br></br>
              </CardContent>
            </Card>
            {/* ----- */}

            <hr />

            {/*  */}
            <h5>Appointment Template</h5>
            <br></br>

            {/* -----Whatsapp Owner Template-----*/}

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Whatsapp Owner Template
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear BRAND NAME you fixed an appointment with CUSTOMER NAME
                  handled by
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.owner_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        owner_v3: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  today at
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.owner_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        owner_v4: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  . Thank you BRAND NAME.
                  {/* <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.owner_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData, owner_v5: e.target.value })
                    }
                    ref={register}
                  /> */}
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  <h5 className="d-flex" style={{ fontSize: "11px" }}>
                    Dear BRAND NAME you fixed an appointment with CUSTOMER NAME
                    handled by $$STAFF$$ today at $$TIME$$. Thank you BRAND NAME
                  </h5>
                </Typography>

                <br></br>
              </CardContent>
            </Card>
            {/* ----- */}

            {/* -----Whatsapp Customer Template-----*/}

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Service RemainderTemplate
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear CUSTOMER NAME, your appointment for
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.customer_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        customer_v3: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  is scheduled for today at
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.customer_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        customer_v4: e.target.value,
                      })
                    }
                    ref={register}
                  />
                  . hope to see you soon. Thank you BRAND NAME.
                  {/* <input
                    type="text"
                    style={{ width: "24%" }}
                    maxLength="30"
                    value={branchData.customer_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setBranch({
                        ...branchData, customer_v5: e.target.value })
                    }
                    ref={register}
                  />*/}
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  <h5 className="d-flex" style={{ fontSize: "11px" }}>
                    Dear CUSTOMER NAME, Thank you for choosing BRAND NAME.
                    Thanks for referring us to $$MemberName$$. We are grateful
                    for your love and support. Kindly contact us XXXX for any
                    assistance. XXXX.
                  </h5>
                </Typography>

                <br></br>
              </CardContent>
            </Card>
            {/* ----- */}

            <hr />
            {branchData.branch_type === "2-Vehicle" ? (
              <div>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={branchData.service_rem_status}
                    name="point_temp_status"
                    onChange={(e) =>
                      setBranch({
                        ...branchData,
                        service_rem_status: !branchData.service_rem_status,
                      })
                    }
                    ref={register}
                  />
                </Typography>

                <h5>Vichle Serivice Remainder</h5>
                <br></br>

                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <Label>Service Reminder No.Of Days Back1</Label>
                      <input
                        type="text"
                        className="form-control h-auto py-2 px-6"
                        value={branchData.service_remainder_day1}
                        name="days_bk_remainder"
                        onChange={(e) =>
                          setBranch({
                            ...branchData,
                            service_remainder_day1: e.target.value,
                          })
                        }
                        ref={register}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={4}>
                    <FormGroup>
                      <Label>Service Reminder No.Of Days Back2</Label>
                      <input
                        type="text"
                        className="form-control h-auto py-2 px-6"
                        value={branchData.service_remainder_day2}
                        name="service_remainder_day2"
                        onChange={(e) =>
                          setBranch({
                            ...branchData,
                            service_remainder_day2: e.target.value,
                          })
                        }
                        ref={register}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={4}>
                    <FormGroup>
                      <Label>Particular Service Day</Label>
                      <input
                        type="checkbox"
                        checked={branchData.rem_particular_service_day}
                        className="form-control h-auto py-2 px-6"
                        name="rem_particular_day"
                        onChange={(e) =>
                          setBranch({
                            ...branchData,
                            rem_particular_service_day:
                              !branchData.rem_particular_service_day,
                          })
                        }
                        ref={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Whatsapp Customer Template
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h2"
                        className="d-flex flex-wrap align-items-center"
                      >
                        <p>
                          Dear CUSTOMER NAME, Thank you for choosing BRAND NAME.
                        </p>
                        <input
                          type="text"
                          style={{ width: "30%" }}
                          value={serviceRem.s_text1}
                          className="form-control h-auto py-2 px-6 sms-textbox"
                          onChange={(e) =>
                            setServiceRem({
                              ...serviceRem,
                              s_text1: e.target.value,
                            })
                          }
                          ref={register}
                        />
                        <span style={{ color: "red" }}>$$Category$$</span>
                        <span style={{ color: "red" }}>$$Sub Category$$</span>
                        <input
                          type="text"
                          style={{ width: "30%" }}
                          value={serviceRem.s_text2}
                          className="form-control h-auto py-2 px-6 sms-textbox"
                          onChange={(e) =>
                            setServiceRem({
                              ...serviceRem,
                              s_text2: e.target.value,
                            })
                          }
                          ref={register}
                        />
                        <span style={{ color: "red" }}>$$Vehicle Number$$</span>
                        <input
                          type="text"
                          style={{ width: "30%" }}
                          value={serviceRem.s_text3}
                          className="form-control h-auto py-2 px-6 sms-textbox"
                          onChange={(e) =>
                            setServiceRem({
                              ...serviceRem,
                              s_text3: e.target.value,
                            })
                          }
                          ref={register}
                        />
                        <span style={{ color: "red" }}>$$Due Date$$</span>
                        <input
                          type="text"
                          style={{ width: "30%" }}
                          value={serviceRem.s_text4}
                          className="form-control h-auto py-2 px-6 sms-textbox"
                          onChange={(e) =>
                            setServiceRem({
                              ...serviceRem,
                              s_text4: e.target.value,
                            })
                          }
                          ref={register}
                          onBlur={() => {
                            setBranch({
                              ...branchData,
                              servRem3: `${serviceRem.s_text1} $$Category$$ $$Sub Category$$ ${serviceRem.s_text2} $$Vehicle Number$$ ${serviceRem.s_text3} $$Due Date$$ ${serviceRem.s_text4}`
                            });
                          }
                        }
                        />
                        <p>Kindly contact us</p>
                        <input
                          type="text"
                          style={{ width: "30%" }}
                          maxLength="30"
                          value={branchData.servRem4}
                          className="form-control h-auto py-2 px-6 sms-textbox"
                          onChange={(e) =>
                            setBranch({
                              ...branchData,
                              servRem4: e.target.value,
                            })
                          }
                          ref={register}
                        />
                        for any assistance.
                        <input
                          type="text"
                          style={{ width: "30%" }}
                          value={branchData.servRem5}
                          className="form-control h-auto py-2 px-6 sms-textbox"
                          onChange={(e) =>
                            setBranch({
                              ...branchData,
                              servRem5: e.target.value,
                            })
                          }
                          ref={register}
                        />
                        <p>.</p>
                      </Typography>

                      <Typography variant="h5" component="h2" className="mt-4">
                        Template Preview
                      </Typography>
                      <Typography variant="h5" component="h2" gutterBottom>
                        <h5 className="d-flex" style={{ fontSize: "11px" }}>
                          Dear CUSTOMER NAME, Thank you for choosing BRAND NAME.
                          XXXX. Kindly contact us XXXX for any assistance. XXXX.
                        </h5>
                      </Typography>

                      <br></br>
                    </CardContent>
                  </Card>
                </Row>
              </div>
            ) : (
              ""
            )}

            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? (
              <Alert color="danger">{loadDeleteMsg}</Alert>
            ) : null}
          </div>
          <div className="card-footer text-right">
            <a href="/company/branch" className="btn btn-secondary mr-2">
              Cancel
            </a>

            {id &&
              (loadDelete ? (
                <button type="button" className="btn btn-danger mr-2">
                  Deleting..
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-danger mr-2"
                >
                  Delete
                </button>
              ))}
            {loadInser ? (
              <button type="button" className="btn btn-primary">
                Saving..
              </button>
            ) : (
              <button type="submit" className="btn btn-primary ">
                Save
              </button>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddBranch;
