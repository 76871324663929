import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Label, Input, Alert } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { API_BASE_URL } from "../../../../_services/constant";
import { post } from "axios";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "reactstrap";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const WhatsAppTemplate = (props) => {
  let queryParams = useQuery();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const companyName = localStorage.getItem("jwl_brand_name");

  const [tempVar, setTempVar] = useState({
    cust_cat1: "",
    cust_cat2: "",
    ref_1: "",
    ref_2: "",
    ratus1:"",
    ratus2:"",
    gen_cat1:"",
    gen_cat2:"",
  });
  const [param, setParam] = useState({
    // --
    custm_catlog_v3: "",
    custm_catlog_v4: "",
    custm_catlog_v5: "",
    upld_voucher_v3: "",
    upld_voucher_v4: "",
    upld_voucher_v5: "",
    redeem_var3: "",
    stcktrnsfr_var2: "",
    stcktrnsfr_var3: "",
    stcktrnsfr_var4: "",
    video_call_var2: "",
    video_call_var3: "",
    missed_call_var2: "",
    missed_call_var3: "",
    missed_call_var5: "",
    referal_party_v3: "",
    referal_party_v4: "",
    referal_party_v5: "",
    sale_msg_v2: "",
    sale_msg_v4: "",
    try_v3: "",
    try_v4: "",
    try_v5: "",
    ratus_v3: "",
    ratus_v4: "",
    ratus_v5: "",
    thnkyou_v3: "",
    thnkyou_v4: "",
    upload_design_v3: "",
    reference_v3: "",
    reference_v4: "",
    reference_v5: "",
    anivrsry_v3: "",
    anivrsry_v4: "",
    anivrsry_v5: "",
    bday_v3: "",
    bday_v4: "",
    bday_v5: "",
    // --
    welcome_1: "",
    welcome_2: "",
    name: "name",
    welcome: "",
    birthday: "",
    anniversary: "",
    reference: "",
    upload_design: "",
    thank_you: "",
    rate_us: "",
    common: "",
    common_temp: "",
    customised_catalogue: "",
    customised_catalogue_temp: "",
    video_call_request: "",
    video_call_booked: "",
    sales_message: false,
    video_call_new: false,
    missed_call: false,
    redeem: false,
    referrel: false,
    stock_transfer_a: false,
    stock_transfer_b: false,
    stock_accept_a: false,
    stock_accept_b: false,
    upload_voucher: false,
    referral_thirt_party: false,
  });
  const [success, setSuccess] = useState(false);
  const companyLogo =
    API_BASE_URL + "BranchLogo/" + localStorage.getItem("jwl_company_logo");

  useEffect(() => {
    setLoad(true);
    postMethod("api/sms/smssettings/whatsapp_Preview", {}).then((dataD) => {
      var data = dataD?.data[0];
      if (data) {
        const parts = (data.custm_catlog_v3 || "")
          .split("$link$")
          .map((str) => str.trim());
        const [cust_cat1, cust_cat2] = parts.length === 2 ? parts : ["", ""];
        const ref_parts = (data.reference_v3 || "")
          .split("$user2$")
          .map((str) => str.trim());
        const [ref_1, ref_2] = ref_parts.length === 2 ? ref_parts : ["", ""];
        const rat_parts = (data.ratus_v3 || "")
        .split("$link$")
        .map((str) => str.trim());
      const [ratus1, ratus2] = rat_parts.length === 2 ? rat_parts : ["", ""];
      const try_parts = (data.try_v3 || "")
      .split("$link$")
      .map((str) => str.trim());
    const [gen_cat1, gen_cat2] = try_parts.length === 2 ? try_parts : ["", ""];
        setTempVar({
          cust_cat1,
          cust_cat2,
          ref_1,
          ref_2,
          ratus1,
          ratus2,
          gen_cat1,
          gen_cat2,
        });

        setParam({
          ...param,
          // --
          welcome_1: data?.welcome_1,
          welcome_2: data?.welcome_2,
          custm_catlog_v3: data?.custm_catlog_v3,
          custm_catlog_v4: data?.custm_catlog_v4,
          custm_catlog_v5: data?.custm_catlog_v5,
          upld_voucher_v3: data?.upld_voucher_v3,
          upld_voucher_v4: data?.upld_voucher_v4,
          upld_voucher_v5: data?.upld_voucher_v5,
          redeem_var3: data?.redeem_var3,
          stcktrnsfr_var2: data?.stcktrnsfr_var2,
          stcktrnsfr_var3: data?.stcktrnsfr_var3,
          stcktrnsfr_var4: data?.stcktrnsfr_var4,
          video_call_var2: data?.video_call_var2,
          video_call_var3: data?.video_call_var3,
          missed_call_var2: data?.missed_call_var2,
          missed_call_var3: data?.missed_call_var3,
          missed_call_var5: data?.missed_call_var5,
          referal_party_v3: data?.referal_party_v3,
          referal_party_v4: data?.referal_party_v4,
          referal_party_v5: data?.referal_party_v5,
          sale_msg_v2: data?.sale_msg_v2,
          sale_msg_v4: data?.sale_msg_v4,
          try_v3: data?.try_v3,
          try_v4: data?.try_v4,
          try_v5: data?.try_v5,
          ratus_v3: data?.ratus_v3,
          ratus_v4: data?.ratus_v4,
          ratus_v5: data?.ratus_v5,
          thnkyou_v3: data?.thnkyou_v3,
          thnkyou_v4: data?.thnkyou_v4,
          upload_design_v3: data?.upload_design_v3,
          reference_v3: data?.reference_v3,
          reference_v4: data?.reference_v4,
          reference_v5: data?.reference_v5,
          anivrsry_v3: data?.anivrsry_v3,
          anivrsry_v4: data?.anivrsry_v4,
          anivrsry_v5: data?.anivrsry_v5,
          bday_v3: data?.bday_v3,
          bday_v4: data?.bday_v4,
          bday_v5: data?.bday_v5,
          // --
          name: data?.name,
          welcome: data?.welcome,
          welcome_temp: data?.welcome_temp,
          birthday: data?.birthday,
          anniversary: data?.anniversary,
          reference: data?.reference,
          upload_design: data?.upload_design,
          thank_you: data?.thank_you,
          rate_us: data?.rate_us,
          common: data?.common,
          common_temp: data?.common_temp,
          customised_catalogue: data?.customised_catalogue,
          video_call_request: data?.video_call_request,
          video_call_booked: data?.video_call_booked,
          sales_message: data?.sales ? data?.sales : false,
          video_call_new: data?.video_call_new ? data?.video_call_new : false,
          missed_call: data?.appointment_misscall_booked
            ? data?.appointment_misscall_booked
            : false,
          redeem: data?.redeem ? data?.redeem : false,
          referrel: data?.referral ? data?.referral : false,
          stock_transfer_a: data?.stock_transfer ? data?.stock_transfer : false,
          stock_transfer_b: data?.stock1_transfer
            ? data?.stock1_transfer
            : false,
          stock_accept_a: data?.stock_acceptance
            ? data?.stock_acceptance
            : false,
          stock_accept_b: data?.stock1_acceptance
            ? data?.stock1_acceptance
            : false,
          upload_voucher: data?.upload_voucher ? data?.upload_voucher : false,
          referral_thirt_party: data?.referral_thirt_party
            ? data?.referral_thirt_party
            : false,
        });

        setWhatsappParam({
          ...WhatsappParam,
          wht_welcome_images: data?.wht_welcome_images
            ? API_BASE_URL + "Images/" + data?.wht_welcome_images
            : companyLogo,
          wht_welcome_text: data?.wht_welcome_text,
          wht_Birthday_images: data?.wht_Birthday_images
            ? API_BASE_URL + "Images/" + data?.wht_Birthday_images
            : companyLogo,
          wht_Birthday_text: data?.wht_Birthday_text,
          wht_AddSales_images: data?.wht_AddSales_images
            ? API_BASE_URL + "Images/" + data?.wht_AddSales_images
            : companyLogo,
          wht_AddSales_text: data?.wht_AddSales_text,
          wht_Anniversary_images: data?.wht_Anniversary_images
            ? API_BASE_URL + "Images/" + data?.wht_Anniversary_images
            : companyLogo,
          wht_Anniversary_text: data?.wht_Anniversary_text,
          wht_CustomizedCatalogue_images: data?.wht_CustomizedCatalogue_images
            ? API_BASE_URL + "Images/" + data?.wht_CustomizedCatalogue_images
            : companyLogo,
          wht_CustomizedCatalogue_text: data?.wht_CustomizedCatalogue_text,
          wht_General_images: data?.wht_General_images
            ? API_BASE_URL + "Images/" + data?.wht_General_images
            : companyLogo,
          wht_General_text: data?.wht_General_text,
          wht_MissedCall_images: data?.wht_MissedCall_images
            ? API_BASE_URL + "Images/" + data?.wht_MissedCall_images
            : companyLogo,
          wht_MissedCall_text: data?.wht_MissedCall_text,
          wht_Otp_images: data?.wht_Otp_images
            ? API_BASE_URL + "Images/" + data?.wht_Otp_images
            : companyLogo,
          wht_Otp_text: data?.wht_Otp_text,
          wht_RateUs_images: data?.wht_RateUs_images
            ? API_BASE_URL + "Images/" + data?.wht_RateUs_images
            : companyLogo,
          wht_RateUs_text: data?.wht_RateUs_text,
          wht_Redeem_images: data?.wht_Redeem_images
            ? API_BASE_URL + "Images/" + data?.wht_Redeem_images
            : companyLogo,
          wht_Redeem_text: data?.wht_Redeem_text,
          wht_ReferralThirdParty_images: data?.wht_ReferralThirdParty_images
            ? API_BASE_URL + "Images/" + data?.wht_ReferralThirdParty_images
            : companyLogo,
          wht_ReferralThirdParty_text: data?.wht_ReferralThirdParty_text,
          wht_Referrence_images: data?.wht_Referrence_images
            ? API_BASE_URL + "Images/" + data?.wht_Referrence_images
            : companyLogo,
          wht_Referrence_text: data?.wht_Referrence_text,
          wht_StockAcpA_images: data?.wht_StockAcpA_images
            ? API_BASE_URL + "Images/" + data?.wht_StockAcpA_images
            : companyLogo,
          wht_StockAcpA_text: data?.wht_StockAcpA_text,
          wht_StockAcpB_images: data?.wht_StockAcpB_images
            ? API_BASE_URL + "Images/" + data?.wht_StockAcpB_images
            : companyLogo,
          wht_StockAcpB_text: data?.wht_StockAcpB_text,
          wht_StockTrxA_images: data?.wht_StockTrxA_images
            ? API_BASE_URL + "Images/" + data?.wht_StockTrxA_images
            : companyLogo,
          wht_StockTrxA_text: data?.wht_StockTrxA_text,
          wht_StockTrxB_images: data?.wht_StockTrxB_images
            ? API_BASE_URL + "Images/" + data?.wht_StockTrxB_images
            : companyLogo,
          wht_StockTrxB_text: data?.wht_StockTrxB_text,
          wht_ThankYou_images: data?.wht_ThankYou_images
            ? API_BASE_URL + "Images/" + data?.wht_ThankYou_images
            : companyLogo,
          wht_ThankYou_text: data?.wht_ThankYou_text,
          wht_UploadDesign_images: data?.wht_UploadDesign_images
            ? API_BASE_URL + "Images/" + data?.wht_UploadDesign_images
            : companyLogo,
          wht_UploadDesign_text: data?.wht_UploadDesign_text,
          wht_UploadVoucher_images: data?.wht_UploadVoucher_images
            ? API_BASE_URL + "Images/" + data?.wht_UploadVoucher_images
            : companyLogo,
          wht_UploadVoucher_text: data?.wht_UploadVoucher_text,
          wht_VideoCallBooked_images: data?.wht_VideoCallBooked_images
            ? API_BASE_URL + "Images/" + data?.wht_VideoCallBooked_images
            : companyLogo,
          wht_VideoCallBooked_text: data?.wht_VideoCallBooked_text,
          wht_VideoCallRequest_images: data?.wht_VideoCallRequest_images
            ? API_BASE_URL + "Images/" + data?.wht_VideoCallRequest_images
            : companyLogo,
          wht_VideoCallRequest_text: data?.wht_VideoCallRequest_text,
          wht_VideoCall_images: data?.wht_VideoCall_images
            ? API_BASE_URL + "Images/" + data?.wht_VideoCall_images
            : companyLogo,
          wht_VideoCall_text: data?.wht_VideoCall_text,
        });
      }

      setLoad(false);
    });
  }, []);

  const _Save = () => {
    let temparam = {
      //--
      welcome_1: param.welcome_1,
      welcome_2: param.welcome_2,
      custm_catlog_v3: param.custm_catlog_v3,
      custm_catlog_v4: param.custm_catlog_v4,
      custm_catlog_v5: param.custm_catlog_v5,
      upld_voucher_v3: param.upld_voucher_v3,
      upld_voucher_v4: param.upld_voucher_v4,
      upld_voucher_v5: param.upld_voucher_v5,
      redeem_var3: param.redeem_var3,
      stcktrnsfr_var2: param.stcktrnsfr_var2,
      stcktrnsfr_var3: param.stcktrnsfr_var3,
      stcktrnsfr_var4: param.stcktrnsfr_var4,
      video_call_var2: param.video_call_var2,
      video_call_var3: param.video_call_var3,
      missed_call_var2: param.missed_call_var2,
      missed_call_var3: param.missed_call_var3,
      missed_call_var5: param.missed_call_var5,
      referal_party_v3: param.referal_party_v3,
      referal_party_v4: param.referal_party_v4,
      referal_party_v5: param.referal_party_v5,
      sale_msg_v2: param.sale_msg_v2,
      sale_msg_v4: param.sale_msg_v4,
      try_v3: param.try_v3,
      try_v4: param.try_v4,
      try_v5: param.try_v5,
      ratus_v3: param.ratus_v3,
      ratus_v4: param.ratus_v4,
      ratus_v5: param.ratus_v5,
      thnkyou_v3: param.thnkyou_v3,
      thnkyou_v4: param.thnkyou_v4,
      upload_design_v3: param.upload_design_v3,
      reference_v3: param.reference_v3,
      reference_v4: param.reference_v4,
      reference_v5: param.reference_v5,
      anivrsry_v3: param.anivrsry_v3,
      anivrsry_v4: param.anivrsry_v4,
      anivrsry_v5: param.anivrsry_v5,
      bday_v3: param.bday_v3,
      bday_v4: param.bday_v4,
      bday_v5: param.bday_v5,

      // --
      name: param.name,
      otp_temp: param.otp,
      welcome: param.welcome,
      birthday: param.birthday,
      anniversary: param.anniversary,
      reference: param.reference,
      upload_design: param.upload_design,
      thank_you: param.thank_you,
      rate_us: param.rate_us,
      common: param.common,
      common_temp: param.common_temp,
      customised_catalogue: param.customised_catalogue,
      video_call_request: param.video_call_request,
      video_call_booked: param.video_call_booked,
      sales_message: param.sales_message,
      video_call_new: param.video_call_new,
      missed_call: param.missed_call,
      redeem: param.redeem,
      referrel: param.referrel,
      stock_transfer_a: param.stock_transfer_a,
      stock_transfer_b: param.stock_transfer_b,
      stock_accept_a: param.stock_accept_a,
      stock_accept_b: param.stock_accept_b,
      upload_voucher: param.upload_voucher,
      referral_thirt_party: param.referral_thirt_party,
      wht_welcome_text: WhatsappParam.wht_welcome_text,
      wht_Birthday_text: WhatsappParam.wht_Birthday_text,
      wht_Anniversary_text: WhatsappParam.wht_Anniversary_text,
      wht_Referrence_text: WhatsappParam.wht_Referrence_text,
      wht_ThankYou_text: WhatsappParam.wht_ThankYou_text,
      wht_UploadDesign_text: WhatsappParam.wht_UploadDesign_text,
      wht_RateUs_text: WhatsappParam.wht_RateUs_text,
      wht_General_text: WhatsappParam.wht_General_text,
      wht_AddSales_text: WhatsappParam.wht_AddSales_text,
      wht_ReferralThirdParty_text: WhatsappParam.wht_ReferralThirdParty_text,
      wht_MissedCall_text: WhatsappParam.wht_MissedCall_text,
      wht_VideoCall_text: WhatsappParam.wht_VideoCall_text,
      wht_Redeem_text: WhatsappParam.wht_Redeem_text,
      wht_StockTrxA_text: WhatsappParam.wht_StockTrxA_text,
      wht_StockTrxB_text: WhatsappParam.wht_StockTrxB_text,
      wht_StockAcpA_text: WhatsappParam.wht_StockAcpA_text,
      wht_StockAcpB_text: WhatsappParam.wht_StockAcpB_text,
      wht_UploadVoucher_text: WhatsappParam.wht_UploadVoucher_text,
      wht_CustomizedCatalogue_text: WhatsappParam.wht_CustomizedCatalogue_text,
      wht_VideoCallRequest_text: WhatsappParam.wht_VideoCallRequest_text,
      wht_VideoCallBooked_text: WhatsappParam.wht_VideoCallBooked_text,
    };
    setLoadInsert(true);
    postMethod("api/sms/whatsapp/insert", temparam).then((data) => {
      // alert(JSON.stringify(temparam));
      if (data.data[0].valid) {
        alert(JSON.stringify(data));
        setSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoadInsert(false);
      }
    });
  };

  const [WhatsappParam, setWhatsappParam] = useState({
    textdata: "",
    product_images: [],
    template_name: "",
    wht_welcome_text: "",
    wht_welcome_images: companyLogo,
    wht_Birthday_text: "",
    wht_Birthday_images: companyLogo,
    wht_Anniversary_text: "",
    wht_Anniversary_images: companyLogo,
    wht_Referrence_text: "",
    wht_Referrence_images: companyLogo,
    wht_ThankYou_text: "",
    wht_UploadDesign_text: "",
    wht_UploadDesign_images: companyLogo,
    wht_RateUs_text: "",
    wht_General_text: "",
    wht_AddSales_text: "",
    wht_ReferralThirdParty_text: "",
    wht_MissedCall_text: "",
    wht_VideoCall_text: "",
    wht_Redeem_text: "",
    wht_StockTrxA_text: "",
    wht_StockTrxB_text: "",
    wht_StockAcpA_text: "",
    wht_StockAcpB_text: "",
    wht_UploadVoucher_text: "",
    wht_CustomizedCatalogue_text: "",
    wht_VideoCallRequest_text: "",
    wht_VideoCallBooked_text: "",
    wht_AddSales_images: companyLogo,
    wht_CustomizedCatalogue_images: companyLogo,
    wht_General_images: companyLogo,
    wht_MissedCall_images: companyLogo,
    wht_Otp_images: companyLogo,
    wht_RateUs_images: companyLogo,
    wht_Redeem_images: companyLogo,
    wht_ReferralThirdParty_images: companyLogo,
    wht_StockAcpA_images: companyLogo,
    wht_StockAcpB_images: companyLogo,
    wht_StockTrxA_images: companyLogo,
    wht_StockTrxB_images: companyLogo,
    wht_ThankYou_images: companyLogo,
    wht_UploadVoucher_images: companyLogo,
    wht_VideoCallBooked_images: companyLogo,
    wht_VideoCallRequest_images: companyLogo,
    wht_VideoCall_images: companyLogo,
  });
  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  // const uploadImageFunc = (formData) => {
  //   const url = API_BASE_URL + `api/masters/product/uploadImage`;
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //       "auth-token": localStorage.getItem("jwl_token"),
  //     },
  //   };
  //   try {
  //     post(url, formData, config).then(
  //       (response) => {},
  //       (error) => {
  //         setSuccess(true);
  //       }
  //     );
  //   } catch (error) {
  //     console.log("error".error);
  //   }
  // };
  // function handleInsert(file_id, template_textdata) {
  //   let file = document.getElementById(file_id);
  //   let files = file?.files;
  //   let formData = new FormData();
  //   WhatsappParam.template_name = file_id;
  //   WhatsappParam.textdata = template_textdata;
  //   console.log("files", files);
  //   if (files?.length > 0) {
  //     for (var j = 0; j < files?.length; j++) {
  //       let file_name = "image-" + createGuid();
  //       WhatsappParam?.product_images.push(
  //         file_name +
  //           "" +
  //           files[j]?.name.substring(files[j]?.name.lastIndexOf("."))
  //       );
  //       formData.append("files", files[j], file_name);
  //     }
  //   }
  //   uploadImageFunc(formData);

  const uploadImage = async (formData) => {
    const url = `${API_BASE_URL}api/masters/product/uploadImage`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };
  
    try {
      await post(url, formData, config);
      setSuccess(true);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  
  function handleInsert(fileId, templateTextData) {
    const fileInput = document.getElementById(fileId);
    const files = fileInput?.files;
  
    if (files?.length) {
      const formData = new FormData();
      WhatsappParam.template_name = fileId;
      WhatsappParam.textdata = templateTextData;
  
      Array.from(files).forEach((file) => {
        const fileName = `image-${createGuid()}${file.name.substring(file.name.lastIndexOf("."))}`;
        WhatsappParam.product_images.push(fileName);
        formData.append("files", file, fileName);
      });
  
      uploadImage(formData);
    } else {
      console.warn("No files selected");
    }
  
    try {
      postMethod("api/sms/whatsapp/insert_files", WhatsappParam).then(
        (data) => {
          if (data.data) {
            alert("Template Save Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong");
          }
        }
      );
    } catch (error) {
      console.log("error", error);
    }
    alert("Saved");
  }

  function setPreviewImage(e, name) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setWhatsappParam({ ...WhatsappParam, [name]: reader.result });
    };
    reader.readAsDataURL(file);
  }

  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(_Save)}>
        <input type="hidden" name="branch_id" value={param.branch_id} />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add WhatsApp Templete</h3>
            </div>
          </div>
          <div className="card-body">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Customer Head
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Dear Customer Name :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.name === "name" ? true : false}
                    name="customerName"
                    onChange={(e) => setParam({ ...param, name: "name" })}
                  />
                  <span className="ml-4">|| Dear Customer :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.name === "Customer" ? true : false}
                    name="customer"
                    onChange={(e) => setParam({ ...param, name: "Customer" })}
                  />
                  <span className="ml-4">|| Dear Member :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.name === "Member" ? true : false}
                    name="member"
                    onChange={(e) => setParam({ ...param, name: "Member" })}
                  />
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  OTP
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  {"Dear Member, XXXX is your one-time password (OTP) for login. Please enter the OTP to proceed." +
                    companyName.toUpperCase() +
                    "."}
                </Typography>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Welcome
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  <span>Dear Member, welcome to</span>{" "}
                  <input
                    type="text"
                    value={param.welcome_1}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, welcome_1: e.target.value })
                    }
                    ref={register}
                  />{" "}
                  ! We honoured and hope you will have a great experience.{" "}
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.welcome_2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, welcome_2: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>Dear Member, welcome to </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.welcome_1 ? param.welcome_1 : "$variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      ! We are honored and hope you will have a great
                      experience.{" "}
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.welcome_2 ? param.welcome_2 : "$variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.welcome}
                    name="exhibition"
                    onChange={(e) =>
                      setParam({ ...param, welcome: !param.welcome })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_welcome_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_welcome_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_welcome_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_welcome_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_welcome_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_welcome_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert("wht_welcome_", WhatsappParam.wht_welcome_text)
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            {/* BirthDay */}
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Birthday
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, Thank you for choosing
                  {companyName.toUpperCase()}
                  .
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.bday_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, bday_v3: e.target.value })
                    }
                    ref={register}
                  />
                  . Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.bday_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, bday_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.bday_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, bday_v5: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>

                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.bday_v3 ? param.bday_v3 : "$Variable$"}
                    </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.bday_v4 ? param.bday_v4 : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.bday_v5 ? param.bday_v5 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.birthday}
                    name="birthday"
                    onChange={(e) =>
                      setParam({ ...param, birthday: !param.birthday })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_Birthday_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_Birthday_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_Birthday_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_Birthday_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_Birthday_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_Birthday_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_Birthday_",
                      WhatsappParam.wht_Birthday_text
                    )
                  }
                  className="btn btn-primary"
                >
                  Save
                </button>
              </CardContent>
            </Card>

            {/* ANNIVERSARY */}
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Anniversary
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, Thank you for choosing
                  {companyName.toUpperCase()}
                  .
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.anivrsry_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, anivrsry_v3: e.target.value })
                    }
                    ref={register}
                  />
                  . Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.anivrsry_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, anivrsry_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.anivrsry_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, anivrsry_v5: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.anivrsry_v3 ? param.anivrsry_v3 : "$Variable$"}
                    </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.anivrsry_v4 ? param.anivrsry_v4 : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.anivrsry_v5 ? param.anivrsry_v5 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.anniversary}
                    name="birthday"
                    onChange={(e) =>
                      setParam({ ...param, anniversary: !param.anniversary })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_Anniversary_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_Anniversary_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_Anniversary_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_Anniversary_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_Anniversary_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_Anniversary_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_Anniversary_",
                      WhatsappParam.wht_Anniversary_text
                    )
                  }
                  className="btn btn-primary"
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  REFERRENCE
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, Thank you for choosing
                  {companyName.toUpperCase()}
                  .
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.ref_1}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setTempVar({ ...tempVar, ref_1: e.target.value })
                    }
                    ref={register}
                  />
                  <span style={{ color: "red" }}>$user2$</span>
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.ref_2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) => {
                      const updatedCustCat2 = e.target.value;
                      setTempVar((prevTempVar) => ({
                        ...prevTempVar,
                        ref_2: updatedCustCat2,
                      }));

                      setParam((prevParam) => ({
                        ...prevParam,
                        reference_v3: `${tempVar.ref_1} $user2$ ${updatedCustCat2}`,
                      }));
                    }}
                    ref={register}
                  />
                  . Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.reference_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, reference_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.reference_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, reference_v5: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.reference_v3 ? param.reference_v3 : "$Variable$"}
                    </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.reference_v4 ? param.reference_v4 : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.reference_v5 ? param.reference_v5 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.reference}
                    name="reference"
                    onChange={(e) =>
                      setParam({ ...param, reference: !param.reference })
                    }
                  />
                </Typography>

                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_Referrence_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_Referrence_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>

                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_Referrence_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_Referrence_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_Referrence_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_Referrence_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_Referrence_",
                      WhatsappParam.wht_Referrence_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  UPLOAD DESIGN
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, thank you for sharing designs. We appreciate and
                  will try to get back to you with the closet we have. Team{" "}
                  {companyName.toUpperCase()}.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.upload_design_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, upload_design_v3: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {" "}
                      Dear Member, thank you for sharing designs. We
                      appreciate and will try to get back to you with the closet
                      we have {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.upload_design_v3
                        ? param.upload_design_v3
                        : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.upload_design}
                    name="upload_design"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        upload_design: !param.upload_design,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_UploadDesign_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_UploadDesign_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_UploadDesign_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_UploadDesign_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_UploadDesign_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_UploadDesign_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_UploadDesign_",
                      WhatsappParam.wht_UploadDesign_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  THNAK YOU
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, thank you for visiting{" "}
                  {companyName.toUpperCase()}. Hope you had a great experience.
                  Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.thnkyou_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, thnkyou_v3: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.{" "}
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.thnkyou_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, thnkyou_v4: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {" "}
                      Dear Member, thank you for visiting{" "}
                      {companyName.toUpperCase()}. Hope you had a great
                      experience. Kindly contact us
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.thnkyou_v3 ? param.thnkyou_v3 : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.thnkyou_v4 ? param.thnkyou_v4 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.thank_you}
                    name="thank_you"
                    onChange={(e) =>
                      setParam({ ...param, thank_you: !param.thank_you })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_ThankYou_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_ThankYou_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_ThankYou_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_ThankYou_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_ThankYou_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_ThankYou_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_ThankYou_",
                      WhatsappParam.wht_ThankYou_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  RATE US
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member,Thank you for choosing {companyName.toUpperCase()}
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.ratus1}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setTempVar({ ...tempVar, ratus1: e.target.value })
                    }
                    ref={register}
                  />
                  <span style={{margin:"5px", color:"red"}}>$link$</span>
                   <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.ratus2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) => {
                      const updatedCustCat2 = e.target.value;
                      setTempVar((prevTempVar) => ({
                        ...prevTempVar,
                        ratus2: updatedCustCat2,
                      }));

                      setParam((prevParam) => ({
                        ...prevParam,
                        ratus_v3: `${tempVar.ratus1} $link$ ${updatedCustCat2}`,
                      }));
                    }}
                    ref={register}
                  />
                  . Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.ratus_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, ratus_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.ratus_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, ratus_v5: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {(tempVar.ratus1 ? tempVar.ratus1 : "Variable") +" $link$ " + (tempVar.ratus2 ? tempVar.ratus2: "Variable")}
                   </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.ratus_v4 ? param.ratus_v4 : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.ratus_v5 ? param.ratus_v5 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.rate_us}
                    name="rate_us"
                    onChange={(e) =>
                      setParam({ ...param, rate_us: !param.rate_us })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_RateUs_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_RateUs_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_RateUs_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_RateUs_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_RateUs_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_RateUs_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert("wht_RateUs_", WhatsappParam.wht_RateUs_text)
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  GENERAL, TRY AT HOME, BUSINESS CATALOGUE
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  {/* 
                
                Dear {{1}}, Thank you for choosing {{2}}. {{3}}. Kindly contact us XXXX for any assistance. XXXX.
Dear CUSTOMER NAME, Thank you for choosing BRAND NAME. Just click $$URL$$ to check our XXXX.
Kindly contact us XXXX for any assistance. XXXX.
                */}
                  Dear Member, Thank you for choosing
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.gen_cat1}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setTempVar({ ...tempVar, gen_cat1: e.target.value })
                    }
                    ref={register}
                  />
                   <span style={{color:"red"}}>$link$</span>
                   <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.gen_cat2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) => {
                      const updatedCustCat2 = e.target.value;
                      setTempVar((prevTempVar) => ({
                        ...prevTempVar,
                        gen_cat2: updatedCustCat2,
                      }));

                      setParam((prevParam) => ({
                        ...prevParam,
                        try_v3: `${tempVar.gen_cat1} $link$ ${updatedCustCat2}`,
                      }));
                    }}
                    ref={register}
                  />
                  Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.try_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, try_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.try_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, try_v5: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                    {(tempVar.gen_cat1 ? tempVar.gen_cat1 : "Variable") +" $link$ " + (tempVar.gen_cat2 ? tempVar.gen_cat2: "Variable")}
  
                    </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.try_v4 ? param.try_v4 : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.try_v5 ? param.try_v5 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.common}
                    name="common"
                    onChange={(e) =>
                      setParam({ ...param, common: !param.common })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_General_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_General_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_General_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_General_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_General_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_General_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert("wht_General_", WhatsappParam.wht_General_text)
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Add Sales
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  {/* 
               Dear {{1}}, thank you for {{2}}. Hope to serve you again. Thank you. Team {{3}}. {{4}}
Dear CUSTOMER NAME, thank you for XXXX. Hope to serve you again. Thank you. Team BRAND NAME.
XXXX
               */}
                  Dear Member, thank you for
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.sale_msg_v2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, sale_msg_v2: e.target.value })
                    }
                    ref={register}
                  />
                  .Hope to serve you again. Thank you. Team
                  {companyName.toUpperCase()}.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.sale_msg_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, sale_msg_v4: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>Dear Member, Thank you for</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.sale_msg_v2 ? param.sale_msg_v2 : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      .Hope to serve you again. Thank you. Team
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.sale_msg_v4 ? param.sale_msg_v4 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.sales_message}
                    name="sales_message"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        sales_message: !param.sales_message,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_AddSales_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_AddSales_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_AddSales_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_AddSales_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_AddSales_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_AddSales_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_AddSales_",
                      WhatsappParam.wht_AddSales_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  REFERRAL THIRD PARTY
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  {/* 
               Dear {{1}}, Thank you for choosing {{2}}. {{3}}. Kindly contact us {{4}} for any assistance. {{5}}.
Dear CUSTOMER NAME, Thank you for choosing BRAND NAME. $$MemberName$$ refer you to us.
Provide us a chance to serve you with the best. Kindly contact us XXXX for any assistance. XXXX.
               */}
                  Dear Member, Thank you for choosing
                  {companyName.toUpperCase()}.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.referal_party_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, referal_party_v3: e.target.value })
                    }
                    ref={register}
                  />
                  Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.referal_party_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, referal_party_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance<span>.</span>
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.referal_party_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, referal_party_v5: e.target.value })
                    }
                    ref={register}
                  />
                  <span>.</span>
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.referal_party_v3
                        ? param.referal_party_v3
                        : "$Variable$"}
                    </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.referal_party_v4
                        ? param.referal_party_v4
                        : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.referal_party_v5
                        ? param.referal_party_v5
                        : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.referral_thirt_party}
                    name="referral_thirt_party"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        referral_thirt_party: !param.referral_thirt_party,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_ReferralThirdParty_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_ReferralThirdParty_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_ReferralThirdParty_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_ReferralThirdParty_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_ReferralThirdParty_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_ReferralThirdParty_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_ReferralThirdParty_",
                      WhatsappParam.wht_ReferralThirdParty_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  MISSED CALL APPOINTMENT BOOKED
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  {/* 
Dear {{1}}, your appointment has been booked on {{2}} at {{3}}. Please contact if you need any
assistance. Team {{4}}. {{5}}.
Dear CUSTOMER NAME, your appointment has been booked on $$DATE$$ at $$TIME$$. Please contact if you need
any assistance. Team BRAND ANME. XXXX.
*/}
                  Dear Member, your appointment has been booked on Dear Member,
                  Thank you for choosing
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.missed_call_var2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, missed_call_var2: e.target.value })
                    }
                    ref={register}
                  />
                  at
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.missed_call_var3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, missed_call_var3: e.target.value })
                    }
                    ref={register}
                  />
                  Please contact if you need any assistance. Team{" "}
                  {companyName.toUpperCase()}.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.missed_call_var5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, missed_call_var5: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {" "}
                      Dear Member, your appointment has been booked on Dear
                      Member, Thank you for choosing
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.missed_call_var2
                        ? param.missed_call_var2
                        : "$Variable$"}
                    </span>
                    <span> at</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.missed_call_var3
                        ? param.missed_call_var3
                        : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      {" "}
                      Please contact if you need any assistance. Team{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.referal_party_v5
                        ? param.referal_party_v5
                        : "$Variable$"}
                      .
                    </span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.missed_call}
                    name="sales_message"
                    onChange={(e) =>
                      setParam({ ...param, missed_call: !param.missed_call })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_MissedCall_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_MissedCall_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_MissedCall_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_MissedCall_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_MissedCall_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_MissedCall_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_MissedCall_",
                      WhatsappParam.wht_MissedCall_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  VIDEO CALL BOOKED
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  {/* 
Dear {{1}}, your video call appointment has been booked on {{2}} at {{3}}. Please contact if you need any
assistance. Team {{4}}.
Dear CUSTOMER NAME, your video call appointment has been booked on $$DATE$$ at $$TIME$$. Please contact if
you need any assistance. Team BRAND NAME. XXXX.

*/}
                  Dear Member, your video call appointment has been booked on{" "}
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.video_call_var2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, video_call_var2: e.target.value })
                    }
                    ref={register}
                  />
                  at
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.video_call_var3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, video_call_var3: e.target.value })
                    }
                    ref={register}
                  />
                  Please contact if you need any assistance. Team{" "}
                  {companyName.toUpperCase()}.
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {" "}
                      Dear Member, your appointment has been booked on{" "}
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.video_call_var2
                        ? param.video_call_var2
                        : "$Variable$"}
                    </span>
                    <span> at</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.video_call_var3
                        ? param.video_call_var3
                        : "$Variable$"}
                    </span>
                    <span>
                      Please contact if you need any assistance. Team{" "}
                      {companyName.toUpperCase()}.
                    </span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.video_call_new}
                    name="video_call_new"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        video_call_new: !param.video_call_new,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_VideoCall_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_VideoCall_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_VideoCall_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_VideoCall_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_VideoCall_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_VideoCall_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_VideoCall_",
                      WhatsappParam.wht_VideoCall_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  REDEEM
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  {/* Dear {{1}}, your voucher has been redeemed successfully. Please contact if you need any assistance.
Team {{2}}. {{3}}.
Dear CUSTOMER NAME, your voucher has been redeemed successfully. Please contact if you need any assistance. Team
BRAND NAME. XXXX. */}
                  Dear Member, your voucher has been redeemed successfully.
                  Please contact if you need any assistance. Team{" "}
                  {companyName.toUpperCase()}.{" "}
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.redeem_var3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, redeem_var3: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>

                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {" "}
                      Dear Member, your voucher has been redeemed successfully.
                      Please contact if you need any assistance. Team{" "}
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.redeem_var3 ? param.redeem_var3 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.redeem}
                    name="redeem"
                    onChange={(e) =>
                      setParam({ ...param, redeem: !param.redeem })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_Redeem_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_Redeem_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_Redeem_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_Redeem_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_Redeem_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_Redeem_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert("wht_Redeem_", WhatsappParam.wht_Redeem_text)
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  STOCK TRANSFER
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                ></Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  <h5 className="d-flex" style={{ fontSize: "11px" }}>
                    Dear BRAND NAME, you transferred $$product count$$ products
                    to $$Other branch name$$. Please check. Thank you. Team
                    Brand Name. XXXX.
                  </h5>
                  {}
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span> Dear BRAND NAME, you transferred</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$product count$${" "}
                    </span>
                    <span>products to</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$Other branch name$$.
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Please check. Thank you. {companyName.toUpperCase()}.
                    </span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.stock_transfer_a}
                    name="stock_transfer_a"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        stock_transfer_a: !param.stock_transfer_a,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_StockTrxA_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_StockTrxA_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_StockTrxA_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_StockTrxA_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_StockTrxA_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_StockTrxA_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_StockTrxA_",
                      WhatsappParam.wht_StockTrxA_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>

                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span> Dear BRAND NAME,</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$Other Branch Name$$
                    </span>
                    <span>accepted</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$Product Quantity$$
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      from our store. Please check. Thank you.
                    </span>
                    <span>{companyName.toUpperCase()}</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      XXXX
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.stock_transfer_b}
                    name="stock_transfer_b"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        stock_transfer_b: !param.stock_transfer_b,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_StockTrxB_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_StockTrxB_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_StockTrxB_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_StockTrxB_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_StockTrxB_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_StockTrxB_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_StockTrxB_",
                      WhatsappParam.wht_StockTrxB_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  STOCK ACCEPTANCE
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                ></Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span> Dear BRAND NAME, </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$OTHER BRANCH NAME$$
                    </span>
                    <span>transfer you</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$QUANTITY$$
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      products. Please check. Thank you.
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      XXXX
                    </span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.stock_accept_a}
                    name="stock_accept_a"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        stock_accept_a: !param.stock_accept_a,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_StockAcpA_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_StockAcpA_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_StockAcpA_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_StockAcpA_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_StockAcpA_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_StockAcpA_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_StockAcpA_",
                      WhatsappParam.wht_StockAcpA_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span> Dear BRAND NAME, our store accepted</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$product Quantity$$
                    </span>
                    <span>products from</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      $$OTHER BRANCH$$.
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      products. Please check. Thank you.
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      XXXX
                    </span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.stock_accept_b}
                    name="stock_accept_b"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        stock_accept_b: !param.stock_accept_b,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_StockAcpB_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_StockAcpB_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_StockAcpB_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_StockAcpB_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_StockAcpB_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) => setPreviewImage(e, "wht_StockAcpB_images")}
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_StockAcpB_",
                      WhatsappParam.wht_StockAcpB_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Upload voucher
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, Thank you for choosing{" "}
                  {companyName.toUpperCase()}.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.upld_voucher_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, upld_voucher_v3: e.target.value })
                    }
                    ref={register}
                  />
                  . Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.upld_voucher_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, upld_voucher_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.upld_voucher_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, upld_voucher_v5: e.target.value })
                    }
                    ref={register}
                  />
                </Typography>
                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>

                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.upld_voucher_v3
                        ? param.upld_voucher_v3
                        : "$Variable$"}
                    </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.upld_voucher_v4
                        ? param.upld_voucher_v4
                        : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.upld_voucher_v5
                        ? param.upld_voucher_v5
                        : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.upload_voucher}
                    name="upload_voucher"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        upload_voucher: !param.upload_voucher,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_UploadVoucher_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_UploadVoucher_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_UploadVoucher_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_UploadVoucher_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_UploadVoucher_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_UploadVoucher_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_UploadVoucher_",
                      WhatsappParam.wht_UploadVoucher_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  CUSTOMISED CATALOGUE
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, Thank you for choosing{" "}
                  {companyName.toUpperCase()}.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.cust_cat1}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setTempVar({ ...tempVar, cust_cat1: e.target.value })
                    }
                    ref={register}
                  />
                  <span style={{ color: "red" }}>$link$</span>
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={tempVar.cust_cat2}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) => {
                      const updatedCustCat2 = e.target.value;
                      setTempVar((prevTempVar) => ({
                        ...prevTempVar,
                        cust_cat2: updatedCustCat2,
                      }));

                      setParam((prevParam) => ({
                        ...prevParam,
                        custm_catlog_v3: `${tempVar.cust_cat1} $link$ ${updatedCustCat2}`,
                      }));
                    }}
                    ref={register}
                  />
                  . Kindly contact us
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.custm_catlog_v4}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, custm_catlog_v4: e.target.value })
                    }
                    ref={register}
                  />
                  for any assistance.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.custm_catlog_v5}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, custm_catlog_v5: e.target.value })
                    }
                    ref={register}
                  />
                  .
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>

                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Dear Member, Thank you for choosing{" "}
                      {companyName.toUpperCase()}.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.custm_catlog_v3 ? tempVar.cust_cat1+" $link$ "+tempVar.cust_cat2 : "$Variable$"}
                    </span>
                    <span>. Kindly contact us</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.custm_catlog_v4
                        ? param.custm_catlog_v4
                        : "$Variable$"}
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      for any assistance.
                    </span>
                    <span style={{ color: "red" }}>
                      {param.custm_catlog_v5
                        ? param.custm_catlog_v5
                        : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.customised_catalogue}
                    name="customised_catalogue"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        customised_catalogue: !param.customised_catalogue,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_CustomizedCatalogue_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_CustomizedCatalogue_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_CustomizedCatalogue_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_CustomizedCatalogue_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_CustomizedCatalogue_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_CustomizedCatalogue_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_CustomizedCatalogue_",
                      WhatsappParam.wht_CustomizedCatalogue_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  VIDEO CALL REQUEST
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className="d-flex flex-wrap align-items-center"
                >
                  Dear Member, welcome to {companyName.toUpperCase()} live
                  support. Our staff executive will reach you soon.
                  <input
                    type="text"
                    style={{ width: "24%" }}
                    value={param.vcall_req_v3}
                    className="form-control h-auto py-2 px-6 sms-textbox"
                    onChange={(e) =>
                      setParam({ ...param, vcall_req_v3: e.target.value })
                    }
                    ref={register}
                  />
                  .
                </Typography>

                <Typography variant="h5" component="h2" className="mt-4">
                  Template Preview
                </Typography>

                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {" "}
                      Dear Member, welcome to {companyName.toUpperCase()} live
                      support. Our staff executive will reach you soon.
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {param.vcall_req_v3 ? param.vcall_req_v3 : "$Variable$"}
                    </span>
                    <span>.</span>
                  </div>
                </Typography>

                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.video_call_request}
                    name="video_call_request"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        video_call_request: !param.video_call_request,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_VideoCallRequest_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_VideoCallRequest_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_VideoCallRequest_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_VideoCallRequest_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_VideoCallRequest_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_VideoCallRequest_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_VideoCallRequest_",
                      WhatsappParam.wht_VideoCallRequest_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  VIDEO CALL BOOKED
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  <h5 className="d-flex" style={{ fontSize: "11px" }}>
                    xyz
                  </h5>
                </Typography>
                <Typography variant="h6" className="mt-4">
                  <span>Active :</span>{" "}
                  <input
                    type="checkbox"
                    checked={param.video_call_booked}
                    name="video_call_booked"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        video_call_booked: !param.video_call_booked,
                      })
                    }
                  />
                </Typography>
                <h6>Whatsapp Message</h6>
                <Input
                  type="textarea"
                  name="textarea"
                  value={WhatsappParam.wht_VideoCallBooked_text}
                  onChange={(e) =>
                    setWhatsappParam({
                      ...WhatsappParam,
                      wht_VideoCallBooked_text: e.target.value,
                    })
                  }
                ></Input>
                <br></br>
                <Label>
                  Upload File<br></br>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {WhatsappParam?.wht_VideoCallBooked_images ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={WhatsappParam?.wht_VideoCallBooked_images}
                        alt="Image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="mt-2"
                    id="wht_VideoCallBooked_"
                    type="file"
                    name="Choose Image"
                    onChange={(e) =>
                      setPreviewImage(e, "wht_VideoCallBooked_images")
                    }
                    ref={register}
                  ></Input>
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    handleInsert(
                      "wht_VideoCallBooked_",
                      WhatsappParam.wht_VideoCallBooked_text
                    )
                  }
                  className="btn btn-primary "
                >
                  Save
                </button>
              </CardContent>
            </Card>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
          </div>
          <div className="card-footer text-right">
            {loadInser ? (
              <button type="button" className="btn btn-primary ">
                Saving..
              </button>
            ) : (
              <button type="submit" className="btn btn-primary ">
                Save
              </button>
            )}
            {/* <br></br>
            <Label className="text-danger mt-2">Please Note By Clicking Save, It Will Remove All Images</Label> */}
          </div>
        </div>
      </Form>
    </>
  );
};

export default WhatsAppTemplate;
