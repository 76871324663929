import React, { Fragment, useState, useEffect } from "react";
import { addDays } from "date-fns";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import PostAddIcon from '@mui/icons-material/PostAdd';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import * as XLSX from "xlsx";
import Whatsapp from "../Whatsapp";
import { postMethod } from "../../../../../_services/_postMethod";
import { API_BASE_URL } from "../../../../../_services/constant";
import "react-awesome-slider/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Textarea,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  ListGroupItem,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  FormText,
  FormFeedback,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import useForceUpdate from "use-force-update";
import {DatePicker, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import "react-tabs/style/react-tabs.css";
import telephoneIcon from "../../../../../_assets/media/telephone.png";
import Chip from "@material-ui/core/Chip";
import { Alert } from "bootstrap";
import AddService from "./AddService";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ServiceAuto(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [DownloadReady, setDownloadReady] = React.useState(false);
  const [DownloadLink, setDownloadLink] = React.useState("");
  const [uploadCustomerDesign, setUploadCustomerDesign] = React.useState([]);

  const get_service_product = () => {
    postMethod("api/customervisit/get_product_service")
      .then((data) => {
        setUploadCustomerDesign(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    get_service_product();
  }, []);

  //Table Config
  const config = {
    page_size: 10,
    length_menu: [10, 5, 20, 50],
  };

  const columns = [
    {
      key: "customer_name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      sortable: true,
      align: "left",
    },
    {
      key: "product_category",
      text: "Category",
      sortable: true,
      align: "left",
    },
    {
      key: "subcategory_name",
      text: "Modal",
      sortable: true,
      align: "left",
    },
    {
      key: "color",
      text: "Color",
      sortable: true,
      align: "left",
    },
    {
      key: "remarks",
      text: "Remark",
      sortable: true,
      align: "left",
    },
    {
      key: "staff_name",
      text: "Staff",
      sortable: true,
      align: "left",
    },
    {
      key: "datetime",
      text: "Purchase Date",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>{moment(record.datetime).format("DD/MM/YYYY")}</Fragment>
        );
      },
    },

    {
      key: "action",
      text: "Service",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <AddService transId={record.tran_id} />
          </Fragment>
        );
      },
    },
  ];
  
  // ---------

  // ---------Add OUTSIDER VICHLES------

  const [openVisit, setOpenVisit] = React.useState({
    visit_count: "",
    full_name: "",
    showVisit: false,
  });

  const [registrationForm, setRegistration] = React.useState(true);
  const [areaValues, setAreaValues] = React.useState({
    area_name: "",
    area: false,
  });
  const [isPleaseWait, setIsPleaseWait] = React.useState(false);
  const [openUploadValues, setUploadValues] = React.useState({
    tran_id: 0,
    customer_id: "",
    mobile: "",
    full_name: "",
    branch_id: "",
    category_id: "",
    remarks: "",
    image_path: "",
  });
  const [registrationFormIndex, setFormIndex] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  const [formType, setFormType] = React.useState("");
  const [openCheckMobile, setOpenCheckMobile] = React.useState(false);
  const [validator, setValidate] = React.useState(new SimpleReactValidator());
  const [mobileNo, setMobileNo] = React.useState(null);
  const [areaList, setAreaList] = React.useState([]);
  const [staffList, setStaffList] = React.useState([]);
  const [referenceList, setReferenceList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [recentCustomerList, setRecentCustomerList] = React.useState([]);
  const [notificationList, setNotificationList] = React.useState([]);
  const [lastCheckinList, setLastCheckinList] = React.useState([]);
  const [openForm, setOpenForm] = React.useState(false);
  const [voucherCount, setVoucherCount] = useState(0);
  const forceUpdate = useForceUpdate();

  // -----------------OutSider vichles Entry-------------------

  const [vichleDetails, setVichleDetails] = useState({
    customer_id: "",
    mobile: "",
    category: "",
    model: "",
    full_name: "",
    vichle_number: "",
    kms: "",
    type: "",
    staff: "",
    color: "",
    payment: "",
    last_service: "",
    next_service: "",
    image: "",
    remark: "",
  });

  // ----------------------------------------------------------
  const [customerDetails, setCustomerDetails] = React.useState({
    customer_id: "",
    full_name: "",
    mobile: "",
    type: "",
    gender: "",
    dob: "",
    doa: "",
    category_name: "",
    staff_id: "",
    profession: "",
    address: "",
    email: "",
    total_visit: "",
    date: "",
  });
  const [registerValues, setRegisterValues] = React.useState({
    customer_id: 0,
    full_name: "",
    mobile: "",
    dob: null,
    doa: null,
    gender: "",
    address: "",
    category_id: "",
    reference_mobile: "",
    reference_name: "",
    ref_id: "",
    area_id: "",
    staff_id: "",
    profession: "",
  });

  const [customerHistory, setCustomerHistory] = React.useState({
    showUpload: false,
  });

  const handleSubmit = () => {
    var btn = document.getElementById("cnbtn");
    btn.disabled = true;
    btn.innerText = "Please Wait...";
    if (validator.fieldValid("Mobile1")) {
      let temparam = {
        mobile: mobileNo,
      };
      postMethod("api/customervisit/getCustomerVisit", temparam).then(
        (dataD) => {
          btn.disabled = false;
          btn.innerText = "Continue";
          var data = dataD.data[0];
          if (data == undefined) {
            setRegisterValues({
              ...registerValues,
              mobile: mobileNo,
            });
            setOpenForm(true);
            setOpenCheckMobile(false);
            setMobileNo(null);
          } else if (formType == "upload") {
            setOpenCheckMobile(false);
            setCustomerHistory({
              ...customerHistory,
              showUpload: true,
            });

            getCustomerDetails(data.mobile);
          }
        }
      );
    } else {
      validator.showMessages();
      forceUpdate();
      btn.disabled = false;
      btn.innerText = "Continue";
    }
  };

  // --------end function

  const getBanners = () => {
    postMethod("api/masters/customer/tabtoscanBannerBrowse", {
      branch_id: "",
    }).then((data) => {
      setBannerList(data.data);
    });
  };
  const getAreaList = () => {
    postMethod("api/customervisit/AreaList").then((res) => {
      setAreaList(res.data);
    });
  };
  const getReferenceList = () => {
    postMethod("api/customervisit/ReferenceList").then((res) => {
      setReferenceList(res.data);
    });
  };
  const getStaffList = () => {
    postMethod("api/customervisit/StaffList").then((res) => {
      setStaffList(res.data);
      //   alert("staff :: " + JSON.stringify(res.data));
    });
  };
  const getCategoryList = () => {
    postMethod("api/customervisit/CategoryList").then((res) => {
      setCategoryList(res.data);
    });
  };
  const getRecentCustomerList = () => {
    postMethod("api/customervisit/getRecentvisiters").then((res) => {
      setRecentCustomerList(res.data);
    });
  };
  const getNotificationList = () => {
    postMethod("api/customervisit/getNotification").then((res) => {
      setNotificationList(res.data);
    });
  };

  const getLastCheckinCustomerList = () => {
    let temparam = {
      branch_id: localStorage.getItem("jwl_branch_id"),
    };
    postMethod("api/customervisit/LastCheckinCustomerList", temparam).then(
      (res) => {
        if (res.data) setLastCheckinList(res.data);
      }
    );
  };

  useEffect(() => {
    getBanners();
    getAreaList();
    getReferenceList();
    getNotificationList();
    getStaffList();
    getCategoryList();
    getLastCheckinCustomerList();
    getRecentCustomerList();
  }, []);

  const handleDobChange = (date) => {
    setRegisterValues({
      ...registerValues,
      dob: date,
    });
  };

  const handleDoaChange = (date) => {
    setRegisterValues({
      ...registerValues,
      doa: date,
    });
  };

  const CheckVisitFormValidation = () => {
    if (validator.fieldValid("Name")) {
      setRegistration(false);
    } else {
      validator.showMessages();
      forceUpdate();
    }
  };
  const handleRefMobile = (e) => {
    setRegisterValues({
      ...registerValues,
      reference_mobile: e.target.value,
    });
  };

  const handleRefOnBlur = (e) => {
    if (e.target.value.length === 10) {
      let temparam = {
        mobile: e.target.value,
      };
      postMethod("api/customervisit/getCustomerVisit", temparam).then(
        (dataD) => {
          var data = dataD.data[0];
          if (data == undefined) {
            alert("Reference Not Found");
            setRegisterValues({
              ...registerValues,
              reference_name: "",
              ref_id: "",
            });
          } else {
            setRegisterValues({
              ...registerValues,
              reference_name: data.full_name,
              ref_id: data.customer_id,
            });
          }
        }
      );
    }
  };

  const handleSubmitAreaForm = (customer_id) => {
    let temparam = {
      area_id: 0,
      area_name: areaValues.area_name,
    };
    postMethod("api/customervisit/insertArea", temparam).then((data) => {
      if (data.data[0].valid) {
        setAreaValues({
          ...areaList,
          area_name: "",
          area: false,
        });
        getAreaList();
      }
    });
  };

  const handleSubmitVisitForm = () => {
    let temparam = {
      customer_id: registerValues.customer_id,
      mobile: registerValues.mobile,
      full_name: registerValues.full_name,
      dob: registerValues.dob,
      category_id: registerValues.category_id,
      doa: registerValues.doa,
      area_id: registerValues.area_id,
      ref_id: registerValues.ref_id,
      staff_id: registerValues.staff_id,
      address: registerValues.address,
      profession: registerValues.profession,
      gender: registerValues.gender,
    };
    postMethod("api/customervisit/insertNewCustomerVisit", temparam).then(
      (data) => {
        if (data.data[0].valid) {
          setOpenForm(false);
          setOpenVisit({
            ...openVisit,
            full_name: registerValues.full_name,
            visit_count: "1",
            showVisit: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          setRegisterValues({
            ...registerValues,
            customer_id: 0,
            full_name: "",
            mobile: "",
            dob: null,
            doa: null,
            gender: "",
            address: "",
            category_id: "",
            ref_id: "",
            area_id: "",
            staff_id: "",
            profession: "",
          });
        } else {
        }
      }
    );
  };

  const handleSubmitCustomerVisit = (customer_id) => {
    let temparam = {
      tran_id: 0,
      customer_id: customer_id,
    };
    postMethod("api/customervisit/insertCustomerVisit", temparam).then(
      (data) => {
        setOpenCheckMobile(false);
        setMobileNo(null);
        setOpenVisit({
          ...openVisit,
          full_name: data.data[0].customer_name,
          visit_count: data.data[0].total_visit,
          showVisit: true,
        });
        setTimeout(() => {
          setOpenVisit({
            ...openVisit,
            showVisit: false,
          });
        }, 4000);
        getLastCheckinCustomerList();
      }
    );
  };

  const handleRegisterForm = (e) => {
    setRegisterValues({
      ...registerValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleUploadForm = (e) => {
    setUploadValues({
      ...openUploadValues,
      [e.target.name]: e.target.value,
    });
    // console.log(e.target)
  };

  const getCustomerDetails = (mobileNo) => {
    let temparam = {
      mobile: mobileNo,
    };
    postMethod("api/customervisit/getCustomerDetails", temparam).then(
      (dataD) => {
        var data = dataD.data;
        // alert(JSON.stringify(data));
        setCustomerDetails({
          ...customerDetails,
          customer_id: data.customer_id,
          full_name: data.full_name,
          mobile: data.mobile,
          total_visit: data.visit_count,
          last_visit: data.last_visit,
          gender: data.gender,
          dob: data.dob == null ? "N/A" : data.dob,
          doa: data.doa == null ? "N/A" : data.doa,
          ref_name: data.ref_name,
          ref_mobile: data.ref_mobile,
          category_name: data.category_name,
          area_name: data.area_name,
          staff_name: data.staff_name,
          profession: data.profession,
          address: data.address,
          branch_name: data.branch_name,
        });

        setVichleDetails({
          ...vichleDetails,
          customer_id: data.customer_id,
          full_name: data.full_name,
          mobile: data.mobile,
          staff: data.staff_name,
        });
      }
    );
  };

  // ----onChange for Vichle--

  const handleuploadVichle = (e) => {
    setVichleDetails({
      ...vichleDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleIntegerForm = (e) => {
    const value = e.target.value;

    // Check if the value is a valid integer
    if (!Number.isInteger(Number(value))) {
      alert("It should be a whole number.");
      setVichleDetails({
        ...vichleDetails,
        [e.target.name]: "",
      });
      return;
    }

    setVichleDetails({
      ...vichleDetails,
      [e.target.name]: value,
    });
  };

  // ----------------------forImage start----------------------------------------------

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Set the selected image
      setSelectedImage(file);

      // Generate a preview URL for the selected image
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  // ----------------------forImage end----------------------------------------------

  // ----------------save data ---------------------------

  const saveOutsideVicle = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("mobile", vichleDetails.mobile);
    formData.append("customer_id", vichleDetails.customer_id);
    formData.append("full_name", vichleDetails.full_name);
    formData.append("staff_name", vichleDetails.staff);
    formData.append("color", vichleDetails.color);
    formData.append("payment", vichleDetails.payment);
    formData.append("last_service", vichleDetails.last_service);
    formData.append("next_service", vichleDetails.next_service);
    formData.append("category", vichleDetails.category);
    formData.append("model", vichleDetails.model);
    formData.append("kms", vichleDetails.kms);
    formData.append("vichle_number", vichleDetails.vichle_number);
    formData.append("bb_id", localStorage.getItem("jwl_branch_id"));

    try {
      const response = await axios.post(
        API_BASE_URL + "api/customervisit/save_outsider_vichle",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("jwl_token"),
          },
        }
      );
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const handleFormSubmit = () => {
    saveOutsideVicle();
    setVichleDetails({
      ...vichleDetails,
      customer_id: "",
      mobile: "",
      category: "",
      model: "",
      full_name: "",
      vichle_number: "",
      kms: "",
      type: "",
      staff: "",
      color: "",
      payment: "",
      last_service: "",
      next_service: "",
      image: "",
      remark: "",
    });
    alert("Save");

    setCustomerHistory({
      ...customerHistory,
      showUpload: false,
    });
  };


  const countStyle = {
    position: "absolute",
    top: "-15px",
    right: "-10px",
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "5px",
    minWidth: "20px",
    minHeight: "15px",
    textAlign: "center",
  };

  const buttonStyle = {
    position: "relative",
    borderRadius: "10px",
    right: "18px",
    backgroundColor: "#e8dfc5",
    color: "blue",
    border: "none",
    cursor: "pointer",
    width:"8rem",
    fontSize:"1.2rem"
  };

  // ---------------
  return (
    <>
      <Button className="btn btn-info" style={buttonStyle} onClick={toggle}>
      <PostAddIcon />&nbsp;<span style={{fontSize:"1rem"}}>New</span> <span style={{color:"red"}}>({props.count_serv})</span>
      </Button>

      <Modal
        isOpen={modal}
        zIndex="999999"
        size="xl"
        backdrop="static"
        centered={true}
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">
              <div className="card-title">
                <h3 className="card-label">Customer</h3>
              </div>
            </Col>

            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggle}
              ></i>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <Button
              style={{border: "1px solid orange"}}
                className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
                onClick={() => {
                  setOpenCheckMobile(true);
                  setFormType("upload");
                  setMobileNo("");
                  toggle();
                }}
              >
                <i className="flaticon2-user"></i> Add OutSider Vichles
              </Button>
            </Col>
            <Col sm="6" className="text-right">
              <Whatsapp custInfo={uploadCustomerDesign} />
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody style={{ maxHeight: "450px", overflow: "auto" }}>
          <div className="table-responsive">
            <ReactDatatable
              config={config}
              records={uploadCustomerDesign}
              columns={columns}
            />
          </div>
        </ModalBody>
      </Modal>

      {/* ---------Add outsider Service---------------- */}

      <Modal
        isOpen={customerHistory.showUpload}
        zIndex="9999"
        size="xl"
        backdrop="static"
        centered={true}
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">
              <div className="card-title">
                <h3 className="card-label">Customer</h3>
              </div>
            </Col>

            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={() => {
                  setCustomerHistory({
                    ...customerHistory,
                    showUpload: false,
                  });
                }}
              ></i>
            </Col>
          </Row>
          <Row>
            <Card
              className="shadow ml-4"
              style={{ width: "59rem", marginBottom: "-1%" }}
            >
              <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
                <Col>
                  <i>{vichleDetails.full_name || ""}</i>
                </Col>
                <Col>
                  <i>{vichleDetails.mobile || ""}</i>
                </Col>
                <Col>
                  <h5 color="red">Date : </h5>
                </Col>
                <Col>
                  <Input type="text" value={moment().format("DD/MM/YYYY")} readOnly></Input>
                </Col>
              </CardHeader>
            </Card>
          </Row>
        </ModalHeader>
        <ModalBody style={{ maxHeight: "450px", overflow: "auto" }}>
          <div className="table-responsive">
            {/* ------------------------------------------------------------------------------------------ */}

            <Row>
              <Col sm="9">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="category">Category</Label>
                      <Input
                        type="text"
                        placeholder="Category"
                        name="category"
                        id="category"
                        value={vichleDetails.category || ""}
                        onChange={handleuploadVichle}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="model">Model</Label>
                      <Input
                        type="text"
                        placeholder="Model"
                        name="model"
                        id="model"
                        value={vichleDetails.model || ""}
                        onChange={handleuploadVichle}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="color">Color</Label>
                      <Input
                        type="text"
                        placeholder="Color"
                        name="color"
                        id="color"
                        value={vichleDetails.color || ""}
                        onChange={handleuploadVichle}
                      ></Input>{" "}
                    </FormGroup>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="vichle_number">Vechile Number</Label>
                      <Input
                        type="text"
                        placeholder="Vichle Number"
                        name="vichle_number"
                        id="vichle_number"
                        value={vichleDetails.vichle_number || ""}
                        onChange={handleuploadVichle}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="kms">KMS</Label>
                      <Input
                        type="number"
                        placeholder="KMS"
                        id="kms"
                        name="kms"
                        value={vichleDetails.kms || ""}
                        onChange={handleIntegerForm}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="last_service">Last Service</Label>
                      <Input
                        type="date"
                        placeholder="Last Service"
                        name="last_service"
                        id="last_service"
                        value={vichleDetails.last_service || ""}
                        onChange={handleuploadVichle}
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="staff">Staff Name</Label>
                      <Input
                        type="text"
                        placeholder="Staff"
                        name="staff"
                        id="staff"
                        value={vichleDetails.staff || ""}
                        onChange={handleuploadVichle}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="payment">Payment</Label>
                      <Input
                        type="number"
                        placeholder="Payment"
                        name="payment"
                        id="payment"
                        value={vichleDetails.payment || ""}
                        onChange={handleIntegerForm}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="next_service">Next Service</Label>
                      <Input
                        type="date"
                        placeholder="Next Service"
                        name="next_service"
                        id="next_service"
                        value={vichleDetails.next_service || ""}
                        onChange={handleuploadVichle}
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="remark">Remark</Label>
                      <Input
                        type="textarea"
                        placeholder="Remark"
                        name="remark"
                        id="remark"
                        value={vichleDetails.remark || ""}
                        onChange={handleuploadVichle}
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
              </Col>

              <Col sm="3">
                <Row>
                  <Input
                    className="image-cart"
                    type="file"
                    name="Choose Image"
                    onChange={handleImageChange}
                  ></Input>
                  {selectedImage ? (
                    <img
                      style={{
                        width: "230px",
                        height: "230px",
                        borderRadius: "4px",
                        marginTop: "4px",
                      }}
                      src={imagePreview}
                      alt="Image"
                    />
                  ) : (
                    <img
                      style={{
                        width: "230px",
                        height: "230px",
                        borderRadius: "4px",
                        marginTop: "4px",
                      }}
                      src={
                        "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                      }
                      alt="Image"
                      className="mr-10"
                    />
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-end">
            <Col md="auto">
                <Button
                  size="xl"
                  className="btn btn-success"
                  onClick={handleFormSubmit}
                >
                 Save
                </Button>
              </Col>
            </Row>
            <hr style={{ border: "2px solid blue" }} />
            {/* --------------------------------------------------------------------- */}
          </div>
        </ModalBody>
      </Modal>

      {/* ------------------ADD OUTER SIDER VICHLES -------------------------*/}

      {/* Check Mobile Number Dialog and User Registration Form */}

      <Dialog
        open={openCheckMobile}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <div className="checkMobileField">
              <h3 className="text-dark mb-3">{"Enter Mobile No."}</h3>
              <div className="d-flex align-items-center">
                <img src={telephoneIcon} />
                <TextField
                  autoComplete="new-off"
                  type="tel"
                  fullWidth
                  className="theme-input"
                  value={mobileNo}
                  placeholder="eg:1234567890"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91 - </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setMobileNo(
                      (e.target.value.length <= 10 &&
                        !isNaN(Number(e.target.value)) &&
                        e.target.value) ||
                        (e.target.value == "" ? e.target.value : mobileNo)
                    );
                  }}
                  autoFocus
                  name="Mobile1"
                  error={validator.message(
                    "Mobile1",
                    mobileNo,
                    "required|min:10|max:10"
                  )}
                  helperText={validator.message(
                    "Mobile1",
                    mobileNo,
                    "required|min:10|max:10"
                  )}
                  variant="standard"
                />
              </div>
              <div className="mt-3 ml-5">
                {formType != "checkin" && lastCheckinList.length > 0
                  ? lastCheckinList.map((prop, i) => {
                      return (
                        <Chip
                          key={i}
                          label={prop.mobile}
                          className="mr-2 mobileChip"
                          onClick={(e) => {
                            setMobileNo(e.target.innerText);
                          }}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
            {formType != "checkin" && recentCustomerList.length > 0 ? (
              <div className="row pl-5">
                {recentCustomerList.map((obj, i) => {
                  return (
                    <div
                      className="col-md-3 pointerShow"
                      onClick={() => {
                        setMobileNo(obj.mobile);
                      }}
                    >
                      {obj.mobile}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setOpenCheckMobile(false);
                setMobileNo("");
              }}
              color="secondary"
            >
              Close
            </Button>
            <Button
              id="cnbtn"
              className="btn btn-success"
              onClick={() => {
                handleSubmit();
                setIsPleaseWait(false);
              }}
              color="primary"
            >
              Continue
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* Registration Form */}
      <Dialog
        open={openForm}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            {registrationForm && registrationFormIndex === 0 ? (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.full_name || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      name="full_name"
                      id="filled-basic"
                      label="Name"
                      error={validator.message(
                        "Name",
                        registerValues.full_name,
                        "required"
                      )}
                      helperText={validator.message(
                        "Name",
                        registerValues.full_name,
                        "required"
                      )}
                      variant="filled"
                    />
                  </div>
                  <div className="col-md-5">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.mobile || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Mobile No."
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-2">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.gender || ""}
                        onChange={handleRegisterForm}
                        name="gender"
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      fullWidth
                      label="Date of Birth"
                      className="mb-3 theme-input"
                      value={registerValues.dob}
                      autoOk
                      format="DD-MM-YYYY"
                      onChange={handleDobChange}
                      PopoverProps={{
                        style: { zIndex: 99999 } // Adjust the z-index value as needed
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      fullWidth
                      className="mb-3  theme-input"
                      label="Date of Anniversary"
                      value={registerValues.doa}
                      autoOk
                      format="DD-MM-YYYY"
                      onChange={handleDoaChange}
                      PopoverProps={{
                        style: { zIndex: 99999 } // Adjust the z-index value as needed
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      multiline
                      value={registerValues.address || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Address"
                      name="address"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.full_name || ""}
                      name="full_name"
                      id="filled-basic"
                      label="Name"
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.mobile || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Mobile No."
                      variant="filled"
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      type="number"
                      className="theme-input mb-3"
                      value={registerValues.reference_mobile || ""}
                      onChange={handleRefMobile}
                      onBlur={handleRefOnBlur}
                      name="reference_mobile"
                      id="filled-basic"
                      label="Reference Mobile No."
                      variant="filled"
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.reference_name || ""}
                      id="filled-basic"
                      label="Reference Name"
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.category_id || ""}
                        name="category_id"
                        onChange={handleRegisterForm}
                      >
                        {categoryList.length > 0
                          ? categoryList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.category_id}>
                                  {" "}
                                  {prop.category_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Staff
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.staff_id || ""}
                        name="staff_id"
                        onChange={handleRegisterForm}
                      >
                        {staffList.length > 0
                          ? staffList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.staff_id}>
                                  {" "}
                                  {prop.name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Area
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.area_id || ""}
                        name="area_id"
                        onChange={handleRegisterForm}
                      >
                        {areaList.length > 0
                          ? areaList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.area_id}>
                                  {" "}
                                  {prop.area_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-1 d-flex align-items-center">
                    <Button
                      className="btn btn-success areaBtn"
                      onClick={() => {
                        setAreaValues({
                          ...areaValues,
                          area: true,
                          area_name: "",
                        });
                      }}
                      color="secondary"
                    >
                      +
                    </Button>
                  </div>

                  <div className="col-md-8">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.profession || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      name="profession"
                      id="filled-basic"
                      label="Profession"
                      variant="filled"
                    />
                  </div>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {registrationForm ? (
              ""
            ) : (
              <Button
                className="btn btn-success"
                onClick={() => setRegistration(true)}
                color="secondary"
              >
                Back
              </Button>
            )}
            <Button
              className="btn btn-danger"
              onClick={() => {
                setOpenForm(false);
                setRegisterValues({
                  ...registerValues,
                  customer_id: 0,
                  full_name: "",
                  mobile: "",
                  dob: null,
                  doa: null,
                  gender: "",
                  address: "",
                  category_id: "",
                  ref_id: "",
                  area_id: "",
                  staff_id: "",
                  profession: "",
                });
                setRegistration(true);
              }}
              color="secondary"
            >
              Close
            </Button>

            {registrationForm ? (
              <Button
                className="btn btn-success"
                onClick={() => {
                  CheckVisitFormValidation();
                }}
                color="secondary"
              >
                Continue
              </Button>
            ) : (
              <Button
                className="btn btn-success"
                onClick={handleSubmitVisitForm}
                color="secondary"
              >
                Continue
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>

      {/* ---------------END OUTSIDER VICHLES -------------------------------*/}
    </>
  );
}
