import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";


import { MenuItem } from '@mui/material';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Modal, ModalHeader, ModalBody, ModalFooter,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Alert,
  Form,
  Input,
} from "reactstrap";
import Backdrop from "@material-ui/core/Backdrop";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import { showErrorToast } from "../../../_services/notification";
import { postMethod } from "../../../_services/_postMethod";
import { API_BASE_URL } from "../../../_services/constant";
import CustomerFooter from "./components/footer";
import Loader from "../../modules/loader";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
}));
const CustUpdateInfo = (props) => {
  const classes = useStyles();

  

 
  const[info, setInfo]= useState({
    name: localStorage.getItem("jwl_username"),
    logo: localStorage.getItem("jwl_logo"),
    brand_name: localStorage.getItem("jwl_brand_name"),
    customer_id: localStorage.getItem("jwl_customer_id"),
    branch_id: localStorage.getItem("jwl_customer_branch_id"),
    mobile: localStorage.getItem("cust_mobile"),
  })

  

  function redirect_after_submission() {
    const insta = localStorage.getItem("jwl_instagram");
    const facebook = localStorage.getItem("jwl_facebook");
    const twitter =  localStorage.getItem("jwl_twitter");

    return insta ? window.open('https://www.instagram.com/' + insta, '_blank') : facebook ? window.open('https://www.facebook.com/' + facebook, '_blank') : twitter ? window.open('https://www.twitter.com/' + twitter, '_blank') : "";
  }
  // ---------------------------------------------------------


  

  const handlePhoneClick = () => {
    window.location.href = `tel:${localStorage.getItem("branch_contact")}`;
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);



//   ------------------

const { register, handleSubmit, errors } = useForm();
const [load, setLoad] = useState(false);
const [loadInser, setLoadInsert] = useState(false);
const [loadDelete, setLoadDelete] = useState(false);  
const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
const [category, setCategory] = useState([]);
const [areaList, setAreaList] = React.useState([]);
const [staffList, setStaffList] = React.useState([]);
const [refList, setRefList] = React.useState([]);
const [refDefaultvalue, setRefDefaultvalue] = useState({
  customer_id:"",
  full_name:"",
  mobile:""
});
const [param, setParam] = useState({
  customer_id: info.customer_id == null ? 0 : info.customer_id,
  mobile: "",
  full_name: "",
  email: "",
  dob: null,
  category_id: null,
  doa: null,
  area_id: null,
  ref_id: null,
  staff_id: null,
  address: "",
  profession: "",
  gender: "",
  sp_bdy:null
});
const [success, setSuccess] = useState(false);
const [successDelete, setSuccessDelete] = useState(false); 
const [isvalid, setIsValid] = useState({
  mobile: true,
  email: true,
});
const [valid, setValid] = useState({
  mobile: true,
  email: true,
});

// Customer Insert
const insertData = (values) => {
  setLoadInsert(true);
  if(param.mobile == "")
  {
    showErrorToast("Please fill mobile.")
    setLoadInsert(false);
  } 
  else if (param.mobile.length !== 10) {
    setValid({ ...valid, mobile: false });
    setLoadInsert(false);
  } 
  else{
    postMethod("api/masters/customer_check_mobile", {customer_id:param.customer_id ,mobile : param.mobile}).then((dataD) => {
      setIsValid({ ...isvalid, mobile: dataD.data });
      if(dataD.data == true){
        param.ref_id = param.ref_id == 0 ? null : param.ref_id; 
        postMethod("api/masters/customer/insert", param).then((data) => {
          if (data.data[0].valid) {
            setSuccess(true);
            setTimeout(() => {
                redirect_after_submission();
            }, 2000);
          }
          else{
             setLoadInsert(false);
          }
         });
      }else{
        setLoadInsert(false);
      }
     
   
    });

  }
};

//Mobile check
const checkMobile = (val) => {
  setIsValid({ ...isvalid, mobile: true });
  setParam({ ...param, mobile: val });
  if (val.length !== 10) {
    setValid({ ...valid, mobile: false });
  } else {
    setValid({ ...valid, mobile: true });    
    postMethod("api/masters/customer_check_mobile", {customer_id:param.customer_id ,mobile : val}).then((dataD) => {
      setIsValid({ ...isvalid, mobile: dataD.data });
    });
  }
};
const checkEmail = (val) => {
  setParam({ ...param, email: val });
  var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  if (regex.test(val)) {
    setValid({ ...valid, email: true });
  } else {
    setValid({ ...valid, email: false });
  }
};

const handleDelete = () => {
  var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

  if (proceed) {
      setLoadDelete(true);
    postMethod("api/masters/customer/delete", { customer_id: param.customer_id }).then((data) => {     
      if (data.valid) {
        setSuccess(true);
           setSuccessDelete(true);
        setTimeout(() => {
          props.history.push("/branch/customer");
        }, 2000);
      }
       else
      {
        setSuccessDelete(true);
            setTimeout(() => {
             setSuccessDelete(false);
          }, 4000);
           setLoadDeleteMsg(data.error)
         setLoadDelete(false);
      }
    });
  }
};

useEffect(() => {
  if (info.customer_id !== null) {
    setLoad(true);
    // var temp = window.atob(info.customer_id);
    postMethod("api/masters/customer/preview", { customer_id: info.customer_id }).then((data) => {
     var data = data.data;
      setParam(data);        
      setParam({...param,
        customer_id: info.customer_id,
        date: (data.date ? data.date.split('T')[0] : null),
        mobile: data.mobile,
        email: data.email,
        dob: (data.dob ? data.dob.split('T')[0] : null),
        doa: (data.doa ? data.doa.split('T')[0] : null),
        full_name: data.full_name, 
        category_id: data.category_id,
        area_id: data.area_id,
        ref_id:  data.ref_id,
        staff_id:  data.staff_id,
        address:  data.address,
        gender:  data.gender,
        profession: data.profession,
        sp_bdy:(data.sp_bdy ? data.sp_bdy.split('T')[0] : null),
        });
        setRefDefaultvalue({...refDefaultvalue,customer_id:data.ref_id,mobile:data.mobile,full_name:data.ref_full_name});
      setLoad(false);
    });
  }
  getCategoryList();
  getAreaList();
  getStaffList();
  getRefList();
}, []);

const getCategoryList = () => {
  postMethod("api/masters/customer/category/Categorys", {}).then((data) => setCategory(data));
}

const getAreaList = () => {
  postMethod("api/customervisit/AreaList").then(res => {
      setAreaList(res.data);
  });
}
const getStaffList = () => {
postMethod("api/customervisit/StaffList").then(res => {
    setStaffList(res.data);
})
}
const getRefList = () => {
postMethod("api/customervisit/ReferenceList").then(res => {
  setRefList(res.data);
})
}

// --------------------

  // =============
  return (
    <>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{backgroundColor: 'white'}}>
        <div className="container">
          <img
            className="img-fluid"
            src={
              API_BASE_URL + "BranchLogo/" + localStorage.getItem("jwl_logo")
            }
            height="23%"
            width="23%"
            style={{ margin: "5px" }}
          />
          <span
            className="btn button"
            style={{ float: "right", margin: "20px" }}
            onClick={handlePhoneClick}
          >
            <PhoneIcon style={{ color: "blue", fontSize: "2rem" }} />
          </span>
        </div>
        <div style={{ margin: "5%" }}>




        <Card
  className="my-2"
  style={{
    width: "100%",
    height: "100%",
  }}
>
  <CardHeader tag="h5">Birthday & Anniversary Details</CardHeader>
  <CardBody style={
    {
      width: "100%",
      height: "100%",
      backgroundImage: "url('../../../../siteImg.jpeg')",
      backgroundSize: "cover", // Ensures the image covers the whole card
      backgroundPosition: "center", // Centers the image in the card
    }
  }>
    
    {load ? <Loader /> : null}

    <Form onSubmit={handleSubmit(insertData)}>
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <Row>
            <Col sm={4} className="mb-3">
              <FormGroup>
                <Label><b>Full Name</b></Label>
                <input
                  type="text"
                  value={param.full_name}
                  className="form-control h-auto py-2 px-6"
                  name="full_name"
                  onChange={(e) =>
                    setParam({ ...param, full_name: e.target.value })
                  }
                  ref={register}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>
            <Col sm={4} className="mb-3">
              <FormGroup>
                <Label><b>Mobile</b></Label>
                <input
                  type="number"
                  required
                  value={param.mobile}
                  className="form-control h-auto py-2 px-6"
                  name="mobile"
                  onChange={(e) => checkMobile(e.target.value)}
                  ref={register}
                  style={{ width: '100%' }}
                />
                {!valid.mobile && (
                  <span className="form-text text-danger">
                    Please enter a valid mobile
                  </span>
                )}
                {!isvalid.mobile && (
                  <span className="form-text text-danger">
                    This mobile number is already registered
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col sm={4} className="mb-3">
              <FormGroup>
                <Label><b>Email</b></Label>
                <input
                  type="text"
                  value={param.email}
                  className="form-control h-auto py-2 px-6"
                  name="email"
                  onChange={(e) => checkEmail(e.target.value)}
                  ref={register}
                  style={{ width: '100%' }}
                />
                {!valid.email && (
                  <span className="form-text text-danger">
                    Please enter a valid email
                  </span>
                )}
                {!isvalid.email && (
                  <span className="form-text text-danger">
                    This email is already registered
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col sm={4} className="mb-3">
              <FormGroup>
                <Label><b>Date of Birth</b></Label>
                <TextField
                  type="date"
                  name="dob"
                  value={param.dob || null}
                  className="form-control"
                  ref={register}
                  onChange={(e) =>
                    setParam({ ...param, dob: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>

            <Col sm={4} className="mb-3">
              <FormGroup>
                <Label><b>Spouse's Birthday</b></Label>
                <TextField
                  type="date"
                  name="sp_bdy"
                  value={param.sp_bdy || null}
                  className="form-control"
                  ref={register}
                  onChange={(e) =>
                    setParam({ ...param, sp_bdy: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>

            <Col sm={4} className="mb-3">
              <FormGroup>
                <Label><b>Date of Anniversary</b></Label>
                <TextField
                  type="date"
                  name="doa"
                  value={param.doa || null}
                  className="form-control"
                  ref={register}
                  onChange={(e) =>
                    setParam({ ...param, doa: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>
          </Row>
          
          {success ? <Alert color="success">Saved Successfully</Alert> : null}
          {successDelete ? (
            <Alert color="danger">{loadDeleteMsg}</Alert>
          ) : null}
        </div>
        
        <div className="card-footer text-right">
          <Button className="btn btn-secondary mr-2" onClick={redirect_after_submission()}>
            Cancel
          </Button>

          {loadInser ? (
            <button type="button" className="btn btn-primary">
              Saving..
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          )}
        </div>
      </div>
    </Form>
  </CardBody>
  
</Card>

        </div>
       
        </div>
        <CustomerFooter/>
    </>
  );
};
export default CustUpdateInfo;
