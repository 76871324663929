import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const PieChartComponent = ({ CustomerGraph }) => {
  const [chartData, setChartData] = useState({
    options: {
      labels: [],
    },
    series: [],
  });

  useEffect(() => {
    if (CustomerGraph && CustomerGraph.x_axis && CustomerGraph.y_axis) {
      // Filter out null values and keep corresponding y_axis values
      const filteredData = CustomerGraph.x_axis
        .map((label, index) => ({
          label,
          value: CustomerGraph.y_axis[index],
        }))
        .filter((data) =>data.value !== null);

      const labels = filteredData.map((data) => data.label?data.label:"Other");
      const series = filteredData.map((data) => data.value);

      setChartData({
        options: {
          labels: labels,
        },
        series: series,
      });
    }
  }, [CustomerGraph]);

  return (
    <div className="mixed-chart">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width="400px" // Adjusted chart width
      />
    </div>
  );
};

export default PieChartComponent;
